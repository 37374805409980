import React from 'react';
import $ from "jquery";
import axios from 'axios';
// import logo from './logo.svg';
// import './App.css';

import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

class App extends React.Component {

  constructor(props) {
    super(props);
		this.state = {
			isLoading: false,
			dataSource: {}
		};
	}

  
  /**
   * ReactJS Life Cycle Method, triggered when the DOM elements are loaded and ready to render output.
   */ 
  componentDidMount() {
    //Call Lamda Function which is hosted locally or on AWS
    // axios.get('https://tes2b55w37.execute-api.ap-south-1.amazonaws.com/cards')
    // .then(response=>{
    //   console.log(" 1I AM INSIDE AXIOS CALL  DID MOUNT !!!!!!!!!!!!!!!!!!! ");
    //   this.setState( {
    //     isLoading: false,
    //     dataSource : response.data
    //    }
 
    //   );
    //   console.log("this.state.dataSource");
    //   console.log(this.state.dataSource);
    // }).catch((error) => {
    //   console.log("GOT ERROR99999999999!!!");
    //   console.log(error);
    // });
    console.log(" I AM INSIDE COMPONENT DID MOUNT !!!!!!!!!!!!!!!!!!! ");
    //This method will be called if the user is visiting the site in thier mobile browser.
    this.convertToMobile();
  }

  /**
   *  This method will be used to convert the DOM elemenets when 
   * the user is visiting the site in thier mobile browser.
   */
  convertToMobile() {
    console.log("INSIDE Convert to Mobile")
    // Smooth scroll for the navigation menu and links with .scrollto classes
    //&& window.location.hostname === this.hostname
    var scrolltoOffset = $('#header').outerHeight() - 1;
    $(document).on('click', '.nav-menu a, .mobile-nav a, .scrollto', function (e) {
      if (window.location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '')
      ) {
        var target = $(this.hash);
        if (target.length) {
          e.preventDefault();

          var scrollto = target.offset().top - scrolltoOffset;

          if ($(this).attr("href") === '#header') {
            scrollto = 0;
          }

          $('html, body').animate({
            scrollTop: scrollto
          }, 1500, 'easeInOutExpo');

          if ($(this).parents('.nav-menu, .mobile-nav').length) {
            $('.nav-menu .active, .mobile-nav .active').removeClass('active');
            $(this).closest('li').addClass('active');
          }

          if ($('body').hasClass('mobile-nav-active')) {
            $('body').removeClass('mobile-nav-active');
            $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
            $('.mobile-nav-overly').fadeOut();
          }
          return false;
        }
      }
    });

    // Activate smooth scroll on page load with hash links in the url
    $(document).ready(function () {
      if (window.location.hash) {
        var initial_nav = window.location.hash;
        if ($(initial_nav).length) {
          var scrollto = $(initial_nav).offset().top - scrolltoOffset;
          $('html, body').animate({
            scrollTop: scrollto
          }, 1500, 'easeInOutExpo');
        }
      }
    });

    // Mobile Navigation
    if ($('.nav-menu').length) {
      var $mobile_nav = $('.nav-menu').clone().prop({
        class: 'mobile-nav d-lg-none'
      });
      $('body').append($mobile_nav);
      $('body').prepend('<button type="button" class="mobile-nav-toggle d-lg-none"><i class="icofont-navigation-menu"></i></button>');
      $('body').append('<div class="mobile-nav-overly"></div>');

      $(document).on('click', '.mobile-nav-toggle', function (e) {
        $('body').toggleClass('mobile-nav-active');
        $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
        $('.mobile-nav-overly').toggle();
      });

      $(document).on('click', '.mobile-nav .drop-down > a', function (e) {
        e.preventDefault();
        $(this).next().slideToggle(300);
        $(this).parent().toggleClass('active');
      });

      $(document).click(function (e) {
        var container = $(".mobile-nav, .mobile-nav-toggle");
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          if ($('body').hasClass('mobile-nav-active')) {
            $('body').removeClass('mobile-nav-active');
            $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
            $('.mobile-nav-overly').fadeOut();
          }
        }
      });
    } else if ($(".mobile-nav, .mobile-nav-toggle").length) {
      $(".mobile-nav, .mobile-nav-toggle").hide();
    }


    // Navigation active state on scroll
    var nav_sections = $('section');
    var main_nav = $('.nav-menu, .mobile-nav');

    $(window).on('scroll', function () {
      var cur_pos = $(this).scrollTop() + 200;

      nav_sections.each(function () {
        var top = $(this).offset().top,
          bottom = top + $(this).outerHeight();

        if (cur_pos >= top && cur_pos <= bottom) {
          if (cur_pos <= bottom) {
            main_nav.find('li').removeClass('active');
          }
          main_nav.find('a[href="#' + $(this).attr('id') + '"]').parent('li').addClass('active');
        }
        if (cur_pos < 300) {
          $(".nav-menu ul:first li:first").addClass('active');
        }
      });
    });

    // Toggle .header-scrolled class to #header when page is scrolled
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('#header').addClass('header-scrolled');
      } else {
        $('#header').removeClass('header-scrolled');
      }
    });

    if ($(window).scrollTop() > 100) {
      $('#header').addClass('header-scrolled');
    }

    // Back to top button
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('.back-to-top').fadeIn('slow');
      } else {
        $('.back-to-top').fadeOut('slow');
      }
    });

    $('.back-to-top').click(function () {
      $('html, body').animate({
        scrollTop: 0
      }, 1500, 'easeInOutExpo', function () {
        $(".nav-menu ul:first li:first").addClass('active');
      });

      return false;
    });

  }

  submitLRSHandle() { 
    console.log("inside submit ... submitLRSHandle ... ");
    if(localStorage.getItem("isLoggedIn")) {
         //upon successful validation, forward the user to the home page.
         window.location.href = "/customer-payment-page";
    }

  }
  render() {
    // let { dataSource } = this.state;
		// if (this.state.isLoading) {
		// 	return <div>Loading...</div>;
		// } else {
			return (
        <main id="main">
                  {/* Services Section Starts */}
        <section id="services" className="services section-bg">
          <div className="container">

            <div className="section-title" data-aos="fade-up">
              <h2>Trending Services</h2>
            </div>

            <div className="row">
            <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in">
                <div className="icon-box icon-box-pink">
                  <div className="icon"><i className="far fa-map"></i></div>
                  <h4 className="title">Land Regularization Service ( LRS )</h4>
                  <p className="description" style={{textAlign:"left"}}>
                    <i className="fas fa-check"></i> Individual Plot  <br />
                    <i className="fas fa-check"></i> Layout <br />
                    </p>
                    <hr></hr>
                    <p>
                     <span style={{fontWeight:'bold'}}>INR 1390</span> 
                     <tm style={{fontSize:'10px'}}>*</tm> 
                  </p>
                {/* <div class='pm-button'>
                <a href='https://www.payumoney.com/paybypayumoney/#/6331204134B2D38B89CCD5BF509CF00E'>
                <img src='https://www.payumoney.com/media/images/payby_payumoney/new_buttons/21.png' />
              </a> 
                   </div>*/} 
                <div class="ui-group-buttons">
                    {/* <!-- Button trigger modal --> */}

                    {(localStorage.getItem("isLoggedIn"))  ?
                     <button type="button" 
                     class="btn btn-primary btn-sm" 
                     onClick={this.submitLRSHandle}>
                        Buy Now
                      </button>
                     :
                     <button type="button" 
                     class="btn btn-primary btn-sm" 
                     data-toggle="modal"
                     onClick={this.submitLRSHandle}
                     data-target="#loginModal">
                        Buy Now
                      </button>
                  }
                  </div>
                  <p style={{fontSize:'10px'}}>*Govt Fee Extra</p>

                </div>
              </div>

              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="100">
                <div className="icon-box icon-box-cyan">
                  <div className="icon"><i className='bx bx-building-house'></i></div>
                  <h4 className="title"><a href="/building-permission">Building Services</a></h4>
                  <p className="description" style={{textAlign:"left"}}>
                    <i className="fas fa-check"></i> Before Construction  <br />
                    <i className="fas fa-check"></i> After Construction  <br />
                  </p>
                  <div class="ui-group-buttons">
                    {/* <!-- Button trigger modal --> */}

                    {(localStorage.getItem("isLoggedIn"))  ?
                     <button type="button" 
                     class="btn btn-primary btn-sm" 
                     onClick={this.submitLRSHandle}>
                        Buy Now
                      </button>
                     :
                     <button type="button" 
                     class="btn btn-primary btn-sm" 
                     data-toggle="modal"
                     onClick={this.submitLRSHandle}
                     data-target="#loginModal">
                        Buy Now
                      </button>
                  }
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="200">
                <div className="icon-box icon-box-green">
                  <div className="icon"><i className='bx bxs-bank' ></i></div>
                  <h4 className="title"><a href="/home-loan">Property Loan</a></h4>
                  <p className="description" style={{textAlign:"left"}}>
                    <i className="fas fa-check"></i> Home Loan <br />
                    <i className="fas fa-check"></i> NRI Purchase Loan<br />
                    <i className="fas fa-check"></i> Land Purchase Loan<br />
                  </p>
                <div class="ui-group-buttons">
                    {/* <!-- Button trigger modal --> */}

                    {(localStorage.getItem("isLoggedIn"))  ?
                     <button type="button" 
                     class="btn btn-primary btn-sm" 
                     onClick={this.submitLRSHandle}>
                        Buy Now
                      </button>
                     :
                     <button type="button" 
                     class="btn btn-primary btn-sm" 
                     data-toggle="modal"
                     onClick={this.submitLRSHandle}
                     data-target="#loginModal">
                        Buy Now
                      </button>
                  }
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
                <div className="icon-box icon-box-blue">
                  <div className="icon"><i className="fa fa-balance-scale"></i></div>
                  <h4 className="title"><a href="/legal-gpa-services">Legal Services</a></h4>
                  <p className="description" style={{textAlign:"left"}}>
                    <i className="fas fa-check"></i> GPA Services <br />
                    <i className="fas fa-check"></i> Property Disputes <br />
                    <i className="fas fa-check"></i> Mortgage Services<br />
                  </p>
                  <div class="ui-group-buttons">
                    {/* <!-- Button trigger modal --> */}

                    {(localStorage.getItem("isLoggedIn"))  ?
                     <button type="button" 
                     class="btn btn-primary btn-lg" 
                     onClick={this.submitLRSHandle}>
                        Buy Now
                      </button>
                     :
                     <button type="button" 
                     class="btn btn-primary btn-lg" 
                     data-toggle="modal"
                     onClick={this.submitLRSHandle}
                     data-target="#loginModal">
                        Buy Now
                      </button>
                  }
                  </div>
                </div>
              </div>
              <br/><br/>
            </div>
          </div>
        </section>
        {/* Services Section Ends */}
        {/* Banner Section Starts */}
        <section id="hero">
          <div className="container">
            <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel" data-pause="hover">
              <div className="carousel-inner" >
                <div className="carousel-item active" >
                  <div className="row">
                    <div className="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center" data-aos="fade-up">
                      <div className="content pt-4 pt-lg-0">
                        <h4>One stop solution for all your real estate needs</h4>                        <i className="icofont-check-circled"></i>&nbsp;Property Services <br /><br />
                        <i className="icofont-check-circled"></i>&nbsp;Building Services <br /><br />
                        <i className="icofont-check-circled"></i>&nbsp;Property Loan <br /><br />
                        <i className="icofont-check-circled"></i>&nbsp;Legal Services <br /><br />
                        <a href="#about" className="btn-get-started scrollto">Get Started</a>
                      </div>
                    </div>
                    <div className="col-lg-6 order-1 order-lg-2 hero-iframe" data-aos="fade-up">
                      <iframe width="540" height="440" title="powToonComp"
                        src="https://www.powtoon.com/embed/fIANRs23QnL/"
                        frameBorder="0" allowFullScreen ></iframe>

                    </div>
                 </div>
                </div>

              </div>
            </div>
          </div>
        </section>
        {/* Banner Section Ends */}

        {/* About Section Starts */}
        <section id="about" className="about">
          <div className="container">
            <div className="row">
              <div className="col-lg-6" data-aos="zoom-in">
                <img src="assets/img/about.jpg" className="img-fluid" alt="" />
              </div>
              <div className="col-lg-6 d-flex flex-column justify-contents-center" data-aos="fade-left">
                <div className="content pt-4 pt-lg-0">
                  <h3>Learn more about us</h3>
                  <p className="font-italic">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
                    magna aliqua.
              </p>
                  <ul>
                    <li><i className="icofont-check-circled"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
                    <li><i className="icofont-check-circled"></i> Duis aute irure dolor in reprehenderit in voluptate velit.</li>
                    <li><i className="icofont-check-circled"></i> Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate trideta storacalaperd</li>
                  </ul>
                  <p>
                    Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate tera noden carma palorp mades tera.
              </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* About Section Ends */}

        {/* Services Section Starts */}
        <section id="services" className="services section-bg">
          <div className="container">

            <div className="section-title" data-aos="fade-up">
              <h2>Services</h2>
            </div>

            <div className="row">
              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in">
                <div className="icon-box icon-box-pink">
                  <div className="icon"><i className="far fa-map"></i></div>
                  <h4 className="title"><a href="/land-documents-verification">Property Services</a></h4>
                  <p className="description" style={{textAlign:"left"}}>
                    <i className="fas fa-check"></i> Open Land/Plot  <br />
                    <i className="fas fa-check"></i> Independent House/Flat <br />
                  </p>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="100">
                <div className="icon-box icon-box-cyan">
                  <div className="icon"><i className='bx bx-building-house'></i></div>
                  <h4 className="title"><a href="/building-permission">Building Services</a></h4>
                  <p className="description" style={{textAlign:"left"}}>
                    <i className="fas fa-check"></i> Before Construction  <br />
                    <i className="fas fa-check"></i> After Construction  <br />
                  </p>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="200">
                <div className="icon-box icon-box-green">
                  <div className="icon"><i className='bx bxs-bank' ></i></div>
                  <h4 className="title"><a href="/home-loan">Property Loan</a></h4>
                  <p className="description" style={{textAlign:"left"}}>
                    <i className="fas fa-check"></i> Home Loan <br />
                    <i className="fas fa-check"></i> NRI Purchase Loan<br />
                    <i className="fas fa-check"></i> Land Purchase Loan<br />
                  </p>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
                <div className="icon-box icon-box-blue">
                  <div className="icon"><i className="fa fa-balance-scale"></i></div>
                  <h4 className="title"><a href="/legal-gpa-services">Legal Services</a></h4>
                  <p className="description" style={{textAlign:"left"}}>
                    <i className="fas fa-check"></i> GPA Services <br />
                    <i className="fas fa-check"></i> Property Disputes <br />
                    <i className="fas fa-check"></i> Mortgage Services<br />
                  </p>
                </div>
              </div>

            </div>

          </div>
        </section>
        {/* Services Section Ends */}
        </main>

			);
		// }

  }
}


// function App() {
//   return (
//     <div className="App">
//     </div>
//   );
// }

export default App;
