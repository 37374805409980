import React, { Component } from 'react';
import $ from "jquery";
import FormErrors from "./FormErrors";
import Validate from "./utility/FormValidation";
import {Auth} from 'aws-amplify';

class Register extends Component {

   /**
   * ReactJS Life Cycle Method, triggered when the DOM elements are loaded and ready to render output.
   */
  componentDidMount() {
    console.log(" I AM INSIDE COMPONENT DID MOUNT LAND-SURVEY !!!!!!!!!!!!!!!!!!! ");
    //This method will be called if the user is visiting the site in thier mobile browser.
    this.convertToMobile();
  }

  /**
   *  This method will be used to convert the DOM elemenets when 
   * the user is visiting the site in thier mobile browser.
   */
  convertToMobile() {
    console.log("INSIDE Convert to Mobile")
    // Smooth scroll for the navigation menu and links with .scrollto classes
    //&& window.location.hostname === this.hostname
    var scrolltoOffset = $('#header').outerHeight() - 1;
    $(document).on('click', '.nav-menu a, .mobile-nav a, .scrollto', function (e) {
      if (window.location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '')
      ) {
        var target = $(this.hash);
        if (target.length) {
          e.preventDefault();

          var scrollto = target.offset().top - scrolltoOffset;

          if ($(this).attr("href") === '#header') {
            scrollto = 0;
          }

          $('html, body').animate({
            scrollTop: scrollto
          }, 1500, 'easeInOutExpo');

          if ($(this).parents('.nav-menu, .mobile-nav').length) {
            $('.nav-menu .active, .mobile-nav .active').removeClass('active');
            $(this).closest('li').addClass('active');
          }

          if ($('body').hasClass('mobile-nav-active')) {
            $('body').removeClass('mobile-nav-active');
            $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
            $('.mobile-nav-overly').fadeOut();
          }
          return false;
        }
      }
    });

    // Activate smooth scroll on page load with hash links in the url
    $(document).ready(function () {
      if (window.location.hash) {
        var initial_nav = window.location.hash;
        if ($(initial_nav).length) {
          var scrollto = $(initial_nav).offset().top - scrolltoOffset;
          $('html, body').animate({
            scrollTop: scrollto
          }, 1500, 'easeInOutExpo');
        }
      }
    });

    // Mobile Navigation
    if ($('.nav-menu').length) {
      var $mobile_nav = $('.nav-menu').clone().prop({
        class: 'mobile-nav d-lg-none'
      });
      $('body').append($mobile_nav);
      $('body').prepend('<button type="button" class="mobile-nav-toggle d-lg-none"><i class="icofont-navigation-menu"></i></button>');
      $('body').append('<div class="mobile-nav-overly"></div>');

      $(document).on('click', '.mobile-nav-toggle', function (e) {
        $('body').toggleClass('mobile-nav-active');
        $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
        $('.mobile-nav-overly').toggle();
      });

      $(document).on('click', '.mobile-nav .drop-down > a', function (e) {
        e.preventDefault();
        $(this).next().slideToggle(300);
        $(this).parent().toggleClass('active');
      });

      $(document).click(function (e) {
        var container = $(".mobile-nav, .mobile-nav-toggle");
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          if ($('body').hasClass('mobile-nav-active')) {
            $('body').removeClass('mobile-nav-active');
            $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
            $('.mobile-nav-overly').fadeOut();
          }
        }
      });
    } else if ($(".mobile-nav, .mobile-nav-toggle").length) {
      $(".mobile-nav, .mobile-nav-toggle").hide();
    }


    // Navigation active state on scroll
    var nav_sections = $('section');
    var main_nav = $('.nav-menu, .mobile-nav');

    $(window).on('scroll', function () {
      var cur_pos = $(this).scrollTop() + 200;

      nav_sections.each(function () {
        var top = $(this).offset().top,
          bottom = top + $(this).outerHeight();

        if (cur_pos >= top && cur_pos <= bottom) {
          if (cur_pos <= bottom) {
            main_nav.find('li').removeClass('active');
          }
          main_nav.find('a[href="#' + $(this).attr('id') + '"]').parent('li').addClass('active');
        }
        if (cur_pos < 300) {
          $(".nav-menu ul:first li:first").addClass('active');
        }
      });
    });

    // Toggle .header-scrolled class to #header when page is scrolled
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('#header').addClass('header-scrolled');
      } else {
        $('#header').removeClass('header-scrolled');
      }
    });

    if ($(window).scrollTop() > 100) {
      $('#header').addClass('header-scrolled');
    }

    // Back to top button
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('.back-to-top').fadeIn('slow');
      } else {
        $('.back-to-top').fadeOut('slow');
      }
    });

    $('.back-to-top').click(function () {
      $('html, body').animate({
        scrollTop: 0
      }, 1500, 'easeInOutExpo', function () {
        $(".nav-menu ul:first li:first").addClass('active');
      });

      return false;
    });

  }

  state = {
    //username: "",
    email: "",
    password: "",
    confirmpassword: "",
    registerSuccess : false,
    userValidated  :false,
    errors: {
      cognito: null,
      blankfield: false,
      passwordmatch: false
    }
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
        passwordmatch: false
      }
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    console.log("inside handle submit registration form");

    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
    }

    // AWS Cognito integration here

    try { 
      console.log(" CALLING COGNITO !!! ! ! !  ! ");
      console.log("........1.....email. : ");
      console.log(this.state.email)

      console.log("........1.....password. : ");
      console.log(this.state.password)


      const signUpResponse = await Auth.signUp(
            {
              username:this.state.email,
              password:this.state.password,
               attribute : {
                   email : this.state.email
               } 
            } );
            console.log(signUpResponse);

            this.props.history.push("/login");

    } catch (error ) {
        console.log("ERROR OCCURRED !!! ! ! !  ! ");
        this.setState({
          errors: { ...this.state.errors,cognito:error }
        });
    }

  };

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  }

  render() {
    return (
      <main id="main">
      <section id="faq" class="faq">
        <div class="container" style={{width:'50%', border:"1px red solid"}}>
          <h1>Register</h1>
          <div style={{color:'red'}}>
            <FormErrors formerrors={this.state.errors} />
          </div>
          

          <form onSubmit={this.handleSubmit} class="php-email-form">
            <div class="form-group">
                <input 
                  class="form-control" 
                  type="email"
                  id="email"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  value={this.state.email}
                  onChange={this.onInputChange}
                />
            </div>
            <div class="form-group">
                <input 
                  class="form-control" 
                  type="password"
                  id="password"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.onInputChange}
                />
            </div>
            <div class="form-group">
                <input 
                  class="form-control" 
                  type="password"
                  id="confirmpassword"
                  placeholder="Confirm password"
                  value={this.state.confirmpassword}
                  onChange={this.onInputChange}
                />
            </div>
            <div class="form-group">
                <a href="/forgotpassword">Forgot password?</a>
            </div>
            <div class="form-group">
                    <button class="btn btn-lg btn-primary btn-block text-capitalize" type="submit">Register</button>
                </div>
                <hr></hr>
          </form>
        
        </div>
      </section>
      </main>
    );
  }
}

export default Register;