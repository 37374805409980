import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Auth, Hub } from 'aws-amplify';
import { Link } from 'react-router-dom';


class Header extends Component {

    state = {
        user: null,     //FB/Google  Related User
        customState: null, //FB/Google  Related State
        verificationCode: "",
        email: "",
        password: "",
        isLoggedIn: false,
        errors: {
            cognito: null,
            blankfield: false
        }
    };


    componentDidMount() {
        console.log("Inside CDM in header ............  ");
        console.log(" Printing isLoggedIn ???????????????? : ==> ");

        Hub.listen("auth", ({ payload: { event, data } }) => {
            console.log("Event " + event);

            console.log(data);
            switch (event) {
                case "signIn":
                case 'cognitoHostedUI':
                    console.log(" INSIDE SIGNIN !!!!!!!!!!!!!!!!!!!!");
                    // this.setState(
                    //     { user: data },
                    //     {isLoggedIn : true}
                    //    );
                    localStorage.setItem("isLoggedIn", true);
                    localStorage.setItem("user", data);
                    //upon successful validation, forward the user to the home page.
                    window.location.href = "/customer-home-page";

                    break;
                case "signOut":
                case "oAuthSignOut":
                    localStorage.removeItem("isLoggedIn");
                    localStorage.removeItem("user");
                    console.log(" INSIDEsignOut !!!!!!!!!!!!!!!!!!!!");
                    // this.setState(
                    //     { user: null },
                    //     {isLoggedIn : false}
                    //     );
                    break;
                case "customOAuthState":
                    this.setState({ customState: data });
                    break;
                default:
                    this.setState({ user: null });
                    break;

            }
        });

        Auth.currentAuthenticatedUser()
            .then(user => this.setState({ user }))
            .catch(() => console.log("Not signed in"));

    }

    checkFbLogin = async event => {
        //get the current User Session from AWS Cognito
        const currentSession = await Auth.currentSession();
        console.log(" Printing currentSession : ==> " + currentSession);

    };

    handleLogoutSubmit = async event => {
        console.log("111111 ");
        event.preventDefault();

        // Form validation
        //this.clearErrorState();

        // AWS Cognito integration here
        try {
            console.log("INSIDE LOGOUT FUNCTION !!! ! ! !  ! ");

            // Call AWS Cognito Logout Function here
            await Auth.signOut()

            console.log("...BEFORE...");
            console.log(localStorage.getItem("userEmail"));
            //Clear the user parameters from  localstorage
            localStorage.removeItem("userEmail");
            localStorage.removeItem("isLoggedIn");
            localStorage.removeItem("userToken");

            console.log("...AFTER...");
            console.log(localStorage.getItem("userEmail"));

            //upon successful logout, forward the user to the home page.
            window.location.href = "/";

        } catch (error) {
            console.log("ERROR OCCURRED !!! ! ! !  ! ");
            this.setState({
                errors: { ...this.state.errors, cognito: error }
            });

        }


    };

    render() {
        return (
 
                <header id="header" className="fixed-top">
                    <div className="container d-flex">
                        <div className="logo mr-auto">
                            <a href="/">
                                <img src="./assets/img/logo.PNG" alt="" className="img-fluid" />
                            </a>
                        </div>

                        <nav className="nav-menu d-none d-lg-block">
                            <ul>
                                <li className="drop-down"><a href="">Property Services</a>
                                    <ul>
                                        <li className="drop-down"><a href="#">Open Plot/Land</a>
                                            <ul>
                                                <li><a href="land-survey">Land Survey</a></li>
                                                <li><a href="land-documents-verification">Land Documents Verification</a></li>
                                                <li><a href="land-valuation">Land Valuation</a></li>
                                                <li><a href="land-sale-agreement-franking">Land Sale Agreement & Franking</a></li>
                                                <li><a href="land-resale-agreement">Land Resale Agreement</a></li>
                                                <li><a href="land-sale-deed-agreement">Land Sale Deed Agreemnet</a></li>
                                            </ul>
                                        </li>
                                        <li className="drop-down"><a href="#">Independent House or Flat</a>
                                            <ul>
                                                <li><a href="building-documents-verification">Building Documents Verification</a></li>
                                                <li><a href="building-inspection">Building Inspection</a></li>
                                                <li><a href="building-valuation">Building Valuation</a></li>
                                                <li><a href="building-sale-agreement-franking">Building Sale Agreement & Franking</a></li>
                                                <li><a href="building-resale-agreement">Building Resale Agreemnet</a></li>
                                                <li><a href="building-sale-deed-agreement">Building Sale Deed Agreemnet</a></li>
                                            </ul>
                                        </li>



                                    </ul>
                                </li>

                                <li className="drop-down"><a href="">Building Services</a>
                                    <ul>
                                        <li className="drop-down"><a href="#">Before Construction</a>
                                            <ul>
                                                <li><a href="building-permission">Building Permission</a></li>
                                                <li><a href="land-regularization">Land Regularization</a></li>
                                                <li><a href="electric-connection">Electric Connection</a></li>
                                                <li><a href="borewell-connection">Borewell Connection</a></li>
                                            </ul>
                                        </li>
                                        <li className="drop-down"><a href="#">After Construction</a>
                                            <ul>
                                                <li><a href="house-number">House Number</a></li>
                                                <li><a href="drinking-water-connection">Drinking Water Connection</a></li>
                                                <li><a href="building-occupancy-certificate">Occupancy Certificate</a></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>

                                <li className="drop-down"><a href="home-loan">Property Loan</a>
                                    <ul>
                                        <li className="drop-down"><a href="/home-loan">Home Loan</a>
                                            <ul>
                                                <li ><a href="/home-purchase-loan">Home Purchase Loan</a></li>
                                                <li ><a href="/home-construction-loan">Home Construction Loan</a></li>
                                                <li ><a href="/home-extension-loan">Home Extension Loan</a></li>
                                                <li ><a href="/home-improvement-loan">Home Improvement Loan</a></li>
                                                <li ><a href="/home-conversion-loan">Home Conversion Loan</a></li>
                                            </ul>
                                        </li>
                                        <li><a href="/nri-home-loan"> NRI Home Loan</a></li>
                                        <li><a href="/land-purchase-loan"> Land Purchase Loan</a></li>
                                    </ul>
                                </li>

                                <li className="drop-down"><a href="">Legal Services</a>
                                    <ul>
                                        <li><a href="legal-gpa-services">GPA Services</a></li>
                                        <li><a href="legal-dispute-services">Dispute Services</a></li>
                                        <li><a href="legal-mortgage-services">Mortgage Services</a></li>
                                    </ul>
                                </li>


                            </ul>
                        </nav>

                        <div className="header-social-links">
                            {/* <Link className="linkedin"><i className="icofont-google-map"></i>Hyderabad</Link> */}
                            
                            {(localStorage.getItem("isLoggedIn"))  ?
                             <span style={{fontSize: '10px',fontWeight:'bold'}} className="linkedin">Welcome {localStorage.getItem("userName")}</span> : " " 
                             }
                             {(localStorage.getItem("isLoggedIn"))  ?
                             <Link onClick={this.handleLogoutSubmit}><i className="icofont-logout"></i></Link> : 
                             <Link data-toggle="modal" data-target="#loginModal"><i className="icofont-login"></i></Link>
                             }
                            <Link className="linkedin"><i className="icofont-shopping-cart"></i></Link>
                        </div>
                    </div>
                </header>
        )
    }
}

const mapStateToProps = (state) => ({
    isLoggedIn: state.userAuth.isLoggedIn,
    userEmail: state.userAuth.userEmail,
    userName: state.userAuth.userName,
    userAuthToken: state.userAuth.userAuthToken
});

const mapDispatchToProps = dispatch => ({
    //loginAction: (payload) => dispatch(login(userAuthState))
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);

// export default Header;