import React from 'react';

function CustomerHeader(props) {
    return (
      
      <div>
      <h1>Hello</h1>
      <p><a href="/login"><i className="icofont-login"></i></a></p>
    </div>
      );
  }

  export default CustomerHeader;