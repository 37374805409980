import {userServices} from '../services/userServices';


//ACTIONS
export const loginAction = (payload) => {
    return {
        type: 'LOGIN',
        payload
    }
}

export const logoutAction = (payload) => {

    return {
        type: 'LOGOUT',
        payload
    }

}

    
export const getLoggedInUser = (payload) => {
     console.log(" INSIDE ACTION CLASS !!!!!!getLoggedInUser!!!!!!!");
     
     console.log("payload before ............");
     console.log(payload);
     
     
     userServices.getUser().then( output => { 
         console.log("output ????????????????"); 
         console.log(output)
        payload["isLoggedIn"] = output.email_verified;
        payload["userEmail"] =  output.email;
        payload["userName"] =  output.email;
        console.log("payload ????????????????"); 
        console.log(payload)
         
    }

     );
    //  console.log("loggedUser before ............");
    //  console.log(loggedUser);
     
    return {
        type: 'GET_LOGIN_USER',
        payload
    }
 
    //  console.log("payload after ............");
    //  console.log(payload);


    // return { type: "userConstants.GETALL_SUCCESS", users }

}

export const  getLoggedInUser1 = (payload) =>  {
    console.log(" INSIDE ACTION CLASS !!!!!!getLoggedInUser!!!!!!!");
    return dispatch => {

        userServices.getUser()
            .then(
                payload => dispatch(success(payload)),
            );
    };

    function success(loggedInUser) {
         return { 
             type: "GET_LOGIN_USER", 
             loggedInUser 
        } 
    }
}

