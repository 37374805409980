/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:778e44ee-6248-4f76-9f98-f5ddc2cb4e88",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_7Ic7kktlr",
    "aws_user_pools_web_client_id": "5s3rinp4bbje5prancpb2hqj2v",
    "oauth": {
        "domain": "propfuze13cfbc03-13cfbc03-dev.auth.ap-south-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/",
        "redirectSignOut": "http://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "propfuzes3bucket235247-dev",
    "aws_user_files_s3_bucket_region": "ap-south-1"
};


export default awsmobile;
