
const initialAuthState = {
    isLoggedIn: false,
    userEmail: "",
    userName: "",
    userAuthToken: ""
}


const authenticationReducer = (state = initialAuthState, action) => {
    switch (action.type) {
        case 'LOGIN':
        console.log("Inside authentication Reducer LOGIN !!! action.payload : ");
        console.log(action.payload)
            return {
                ...state,
                isLoggedIn: action.payload.isLoggedIn,
                userEmail: action.payload.userEmail,
                userName: action.payload.userName,
                userAuthToken: action.payload.userAuthToken
            };
        case 'GET_LOGIN_USER':
                console.log("Inside authentication Reducer GET_LOGIN_USER !!! State: ");
                console.log(action.payload)
                return state;
                // return {
                //     ...state,
                //     isLoggedIn: true,
                //     userEmail: action.payload.email,
                //     userName: action.payload.email,
                //     userAuthToken: action.payload.email
                //     };
            case 'LOGOUT':
            return {
                ...state,
                isLoggedIn: initialAuthState.isLoggedIn,
                userEmail: initialAuthState.userEmail,
                userName: initialAuthState.userName,
                userAuthToken: initialAuthState.userAuthToken
            };

        default:
            return state;

    }
}

export default authenticationReducer;