import { createStore, applyMiddleware } from 'redux';
import logger from "redux-logger";
import thunk from 'redux-thunk';
import rootreducer from './reducers';

const middleware = applyMiddleware(logger, thunk);

const store = (createStore(rootreducer, middleware));

console.log("store ==> being imported and called !!!!  :" );
console.log(store);


export default store;