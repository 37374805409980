import React, { Component } from 'react';
import FormErrors from "./FormErrors";
import Validate from "./utility/FormValidation";
import SignInFormValidation from "./utility/SignInFormValidation";
import { Auth } from 'aws-amplify';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

class Footer extends Component {
    // function Footer() {
        state = {
            //        username: "",
            verificationCode: "",
            email: "",
            mobile: "",
            firstName: "",
            lastName: "",
            password: "",
            isLoggedIn: false,
            errors: {
                cognito: null,
                blankfield: false,
                passwordmatch:false
            }
        };
    
    
        clearErrorState = () => {
            this.setState({
                errors: {
                    cognito: null,
                    blankfield: false,
                    passwordmatch:false
                }
            });
        };

        handleFbLogin = () => {
            this.props.userHasAuthenticated(true);
          };

        //this method will authenticate the AWS Cognito users 
        handleFBLoginSubmit = async event => {
            event.preventDefault();
            console.log("Inside FB Login Function handle Submit  !!!!");
    
            // // Form validation
            // this.clearErrorState();
            // const error = Validate(event, this.state);
            // if (error) {
            //     this.setState({
            //         errors: { ...this.state.errors, ...error }
            //     });
            //     return;
            // }
    
            // AWS Cognito integration here
            try {
                const signInFBResponse = await Auth.federatedSignIn({provider: 'Facebook'});
                console.log(signInFBResponse);
    
                this.setState({
                    isLoggedIn: true 
                });
                
                //get the current User Session from AWS Cognito
                const currentSession  = await Auth.currentSession();
                console.log(" Printing currentSession : ==> ");

                console.log(" Printing currentSession  payload: email ==> ");
                console.log(currentSession.getIdToken().payload);
                
                //set the variables in localstorage
                // localStorage.setItem("userEmail",currentSession.getIdToken().payload.email);
                localStorage.setItem("isLoggedIn",true);
                localStorage.setItem("userToken",currentSession.getIdToken().getJwtToken());
            
            
                //set the user Authentication details her
                // userAuthState.isLoggedIn = true;
                // userAuthState.userEmail = this.state.email;
                // userAuthState.userName = this.state.email;
                // userAuthState.userAuthToken = currentSession;

                //userAuthState.userId = 1;
                //dispatching the action to store the user authentication state
                //this.props.loginAction(userAuthState);
                
                // console.log("this.props.isLoggedIn ==> : ");
                // console.log(this.props.isLoggedIn);
        
                //upon successful validation, forward the user to the home page.
                window.location.href = "/customer-home-page";
    
            } catch (error) {
                console.log("ERROR OCCURRED during FB Login!!! ! ! !  ! ");
                this.setState({
                    errors: { ...this.state.errors, cognito: error }
                });
    
            }
    
    
        };
        
        //this method will authenticate the AWS Cognito users 
        handleLoginSubmit = async event => {
            event.preventDefault();
            console.log("Inside Login Function handle Submit  !!!!");
    
            // Form validation
            this.clearErrorState();
            const error = SignInFormValidation(event, this.state);
            if (error) {
                this.setState({
                    errors: { ...this.state.errors, ...error }
                });
                return;
            }
    
            // AWS Cognito integration here
            console.log(" CALLING COGNITO !!! ! ! !  ! ");
            console.log("........1.....email. : ");
            console.log(this.state.email)
    
            console.log("........1.....password. : ");
            console.log(this.state.password)
    
            try {
                const signInResponse = await Auth.signIn(this.state.email, this.state.password);
                console.log(signInResponse);
                console.log(this.props);
    
                this.setState({
                    isLoggedIn: true 
                });
                
                //get the current User Session from AWS Cognito
                const currentSession  = await Auth.currentSession();
                console.log(" Printing currentSession : ==> ");

                console.log(" Printing currentSession  payload: email ==> ");
                console.log(currentSession.getIdToken().payload.phone_number);
                console.log(currentSession.getIdToken().payload.given_name);
                console.log(currentSession.getIdToken().payload.family_name);
                console.log(currentSession.getIdToken().payload.email);
                console.log(currentSession.getIdToken().payload.email_verified);
                console.log(currentSession.getIdToken().getJwtToken());
                
                //set the variables in localstorage 
                localStorage.setItem("userEmail",currentSession.getIdToken().payload.email);
                localStorage.setItem("userName",currentSession.getIdToken().payload.given_name + " " + currentSession.getIdToken().payload.family_name);
                localStorage.setItem("isLoggedIn",true);
                localStorage.setItem("userToken",currentSession.getIdToken().getJwtToken());
          
          
                //set the user Authentication details her
                // userAuthState.isLoggedIn = true;
                // userAuthState.userEmail = this.state.email;
                // userAuthState.userName = this.state.email;
                // userAuthState.userAuthToken = currentSession;

                //userAuthState.userId = 1;
				//dispatching the action to store the user authentication state
				//this.props.loginAction(userAuthState);
                
                // console.log("this.props.isLoggedIn ==> : ");
                // console.log(this.props.isLoggedIn);
        
                //upon successful validation, forward the user to the home page.
                window.location.href = "/customer-home-page";
    
            } catch (error) {
                console.log("ERROR OCCURRED !!! ! ! !  ! ");
                this.setState({
                    errors: { ...this.state.errors, cognito: error }
                });
    
            }
    
    
        };
        
        // This method will authenticate the authorization code entered by user during forgot password process.
        handleFPVerificationCode = async event => {
            event.preventDefault();
        
            console.log("inside handle handleVerificationCode form");
        
            // Form validation
            this.clearErrorState();
            const error = Validate(event, this.state);
            if (error) {
              this.setState({
                errors: { ...this.state.errors, ...error }
              });
              return;
            }
            document.getElementById('registerSuccessDivMsg').style.display ='none';
                
            // AWS Cognito integration here
        
            try { 
    
             console.log("........2.....handleVerificationCode. : ");
             console.log(this.state.email);
             console.log(this.state.verificationCode);
             console.log(this.state.password);
    
            const forgotFPResponse = await Auth.forgotPasswordSubmit(this.state.email,
                                                                          this.state.verificationCode,
                                                                          this.state.password) ;
             console.log(forgotFPResponse);

             ////validationFPSuccessDiv ( show )  //enterValidationFPDiv( hide)
             document.getElementById('enterValidationFPDiv').style.display ='none';
             document.getElementById('validationFPSuccessDiv').style.display ='block';
                
            } catch (error ) {
                console.log("ERROR OCCURRED !!! ! ! !  ! ");
                this.setState({
                  errors: { ...this.state.errors,cognito:error }
                });
                return;
            }
        
          };        
        // This method will authenticate the authorization code entered by user during registration process.
        handleVerificationCode = async event => {
            event.preventDefault();
        
            console.log("inside handle handleVerificationCode form");
        
            // Form validation
            this.clearErrorState();
            const error = Validate(event, this.state);
            if (error) {
              this.setState({
                errors: { ...this.state.errors, ...error }
              });
              return;
            }
            document.getElementById('registerSuccessDivMsg').style.display ='none';
                
            // AWS Cognito integration here
        
            try { 
    
             console.log("........2.....handleVerificationCode. : ");
             console.log(this.state.verificationCode);
    
            const confirmSignUpResponse = await Auth.confirmSignUp(this.state.email,this.state.verificationCode) ;
             console.log(confirmSignUpResponse);

             //validationSuccessDivregisterSuccessDiv
             document.getElementById('registerSuccessDiv').style.display ='none';
             document.getElementById('validationSuccessDiv').style.display ='block';
                
            } catch (error ) {
                console.log("ERROR OCCURRED !!! ! ! !  ! ");
                this.setState({
                  errors: { ...this.state.errors,cognito:error }
                });
                return;
            }
        
          };
    
        // Function used to register the user with email and password
        handleRegisterSubmit = async event => {
            event.preventDefault();
        
            console.log("inside handle submit registration form");
            console.log("........1.....email. : ");
            console.log(this.state.email)
      
            console.log("........2.....password. : ");
            console.log(this.state.password)
            
            console.log("........3.....mobile. : ");
            console.log("+"+this.state.mobile)
            
            console.log("........4.....firstname. : ");
            console.log(this.state.firstName)
            
            console.log("........5.....lastName. : ");
            console.log(this.state.lastName)
      
            // Form validation
            this.clearErrorState();
            const error = Validate(event, this.state);
            if (error) {
            
              this.setState({
                errors: { ...this.state.errors, ...error }
              });
              return;
            }
                
            // AWS Cognito integration here
         
            try { 
              console.log(" CALLING COGNITO !!! ! ! !  ! ");
        
        
              const signUpResponse = await Auth.signUp(
                    {
                      username:this.state.email,
                      password:this.state.password,
                       attributes : {
                        email : this.state.email,
                        given_name : this.state.firstName,
                        family_name : this.state.lastName,
                        phone_number :(this.state.mobile.indexOf("+") === -1) ?  "+"+this.state.mobile :this.state.mobile 
                    } 
                    } );
                    console.log(signUpResponse);
                    console.log("registerDiv: ")
                    console.log(document.getElementById('registerDiv').style.display);
                    document.getElementById('registerDiv').style.display ='none';
                    console.log(document.getElementById('registerDiv').style.display);
                    
                    console.log("registerSuccessDiv: ")
                    console.log(document.getElementById('registerSuccessDiv').style.display);
                    document.getElementById('registerSuccessDiv').style.display ='block';
                    console.log(document.getElementById('registerSuccessDiv').style.display);
                
            } catch (error ) {
                console.log("ERROR OCCURRED !!! ! ! !  ! ");
                this.setState({
                  errors: { ...this.state.errors,cognito:error }
                });

                return;
            }
        
          };
    
        onInputChange = event => {
            this.setState({
                [event.target.id]: event.target.value
            });
            document.getElementById(event.target.id).classList.remove("is-danger");
        };
    
        showRegisterForm = event => {
            console.log('inside show registration form  !! ');
            //         this.setState({
            //             [event.target.id]: event.target.value
            //         });
            // //        document.getElementById(event.target.id).classList.remove("is-danger");
            document.getElementById("registerFormDiv").display = 'block';
        };
    
        showLoginForm = event => {
            this.setState({
                [event.target.id]: event.target.value
            });
            document.getElementById(event.target.id).classList.remove("is-danger");
        };
    
        handleForgotPasswordSubmit = async event => {
            event.preventDefault();
        
            console.log("inside handle handleForgotPasswordSubmit form");
        
            // Form validation
            this.clearErrorState();
            const error = Validate(event, this.state);
            if (error) {
              this.setState({
                errors: { ...this.state.errors, ...error }
              });
              return;
            }
            document.getElementById('registerSuccessDivMsg').style.display ='none';
                
            // AWS Cognito integration here
        
            try { 
    
             console.log("........2.....handleVerificationCode. : ");
             console.log(this.state.verificationCode);
    
            const forgotPasswordResponse = await Auth.forgotPassword(this.state.email) ;
             console.log(forgotPasswordResponse);

             //Open validation form
             document.getElementById('forgotPasswordDiv').style.display ='none';
             document.getElementById('enterValidationFPDiv').style.display ='block';
             
                
            } catch (error ) {
                console.log("ERROR OCCURRED !!! ! ! !  ! ");
                this.setState({
                  errors: { ...this.state.errors,cognito:error }
                });
                return;
            }
        };
    
        handleLoginClick() {
            this.setState({isLoggedIn: true});
        }
    


    render() {

        return ( 
            <footer id="footer">
                <div className="footer-top"> 
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-6">
                                <div className="footer-info">
                                    <h3>PropKwik</h3>
                                    <p>
                                        1-2-14/BC/42 <br />
                                    Brundhavan Colony, Budvel<br />
                                    Rajendra Nagar <br />
                                    Hyderabad - 500030, Telangana, India<br />
                                        <strong>Phone:</strong> +91 9502 58 3838<br />
                                        <strong>Email:</strong> support@propkwik.com<br />
                                    </p>
                                    <div className="social-links mt-3">
                                        <a href="http://www.twitter.com" className="twitter"><i className="bx bxl-twitter"></i></a>
                                        <a href="http://www.facebook.com" className="facebook"><i className="bx bxl-facebook"></i></a>
                                        <a href="http://www.instagram.com" className="instagram"><i className="bx bxl-instagram"></i></a>
                                        <a href="http://www.linkedin.com" className="linkedin"><i className="bx bxl-linkedin"></i></a>
                                        <a href="http://www.medium.com" className="linkedin"><i className="bx bxl-medium"></i></a>
                                    </div>

                                </div>
                            </div>

                            <div className="col-lg-2 col-md-6 footer-links">
                                <h4>Useful Links</h4>
                                <ul>
                                    <li><i className="bx bx-chevron-right"></i> <a href="#">Home</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href="#aboutus">About us</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href="#services">Services</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href="#">Terms of service</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href="#">Privacy policy</a></li>
                                </ul>
                            </div>

                            <div className="col-lg-3 col-md-6 footer-links">
                                <h4>Our Services</h4>
                                <ul>
                                    <li><i className="bx bx-chevron-right"></i> <a href="/land-survey">Property Services</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href="/building-verification">Building Services</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href="/home-loan">Property Loan</a></li>
                                    <li><i className="bx bx-chevron-right"></i> <a href="/legal-gpa-services">Legal Services</a></li>
                                </ul>
                            </div>
                            <div className="col-lg-4 col-md-6 footer-newsletter">
                                <h4>Our Newsletter</h4>
                                <p>Please enter your email address to receive our Newsletter.</p>
                                <form action="" method="post">
                                    <input type="email" name="email" />
                                    <input type="submit" value="Subscribe" />
                                </form>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="copyright">
                        &copy; {new Date().getFullYear()} Copyright <strong><span>Conflate IT Solutions Private Limited</span></strong>. All Rights Reserved
                </div>
                    <div className="credits">
                        Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
                    </div>
                </div>
                <a href="#" className="back-to-top">
                    <i className="bx bxs-up-arrow-alt"></i>
                </a>
                <div className="modal fade" id="exampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Please Enter Your Details</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch" data-aos="fade-left">
                                    {/* <form action="forms/contact.php" method="post" role="form" className="php-email-form"> */}
                                    <div className="form-group">
                                        <label>Your Name</label>
                                        <input type="text" name="name" className="form-control" id="name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                                        <div className="validate"></div>
                                    </div>
                                    <div className="form-group">
                                        <label>Your Email</label>
                                        <input type="email" className="form-control" name="email" id="email" data-rule="email" data-msg="Please enter a valid email" />
                                        <div className="validate"></div>
                                    </div>
                                    <div className="form-group">
                                        <label>Mobile Number</label>
                                        
                                        <input type="text" className="form-control" size="50px" name="subject" id="subject" data-rule="minlen:4" data-msg="Please enter only numbers" />
                                        <div className="validate"></div>
                                    </div>
                                    {/* </form> */}
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button type="button" className="btn btn-primary">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
 
                   {/* Login Modal/Popup with Social Media Logins */}
                   <div class="modal fade" id="loginModal" tabindex="-10"
                      role="dialog" aria-labelledby="loginLabel" aria-hidden="true">
                      <div class="modal-dialog">
                          <div class="modal-content"  >
                              <div class="modal-header" >
                                  <h5 class="modal-title" id="loginLabel">Sign In</h5>
                                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">&times;</span>
                                  </button>
                              </div>
                              <div class="modal-body" >
                                  <div class="align-items-strech" data-aos="fade-left" id="signInDiv">
                                      <form onSubmit={this.handleLoginSubmit} role="form" class="php-email-form">
                                          <div style={{ color: 'red' }}><FormErrors formerrors={this.state.errors} /></div>
                                          <div class="form-group">
                                              <input
                                                  class="form-control"
                                                  type="email"
                                                  id="email"
                                                  aria-describedby="emailHelp"
                                                  placeholder="Enter email"
                                                  value={this.state.email}
                                                  onChange={this.onInputChange}
                                              />
                                          </div>
  
                                          <div class="form-group">
                                              <input
                                                  class="form-control"
                                                  type="password"
                                                  id="password"
                                                  placeholder="Password"
                                                  value={this.state.password}
                                                  onChange={this.onInputChange}
                                              />
                                          </div>
  
                                          <div class="form-group">
                                              <button class="btn btn-lg btn-primary btn-block text-capitalize"
                                                  type="submit">Sign in</button>
                                          </div>
                                          <div class="form-group">
                                              <input type="checkbox" id="customCheck1" />
                                          &nbsp;<label>Remember password</label>
                                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                          <a href="#" data-toggle="modal" data-dismiss="modal" data-target="#forgotPasswordModal">Forgot Password ? </a><br/>
                                          </div>
                                          <div class="form-group">
                                              &nbsp;&nbsp;&nbsp; No Account ?
                                              <a href="#" data-toggle="modal" data-dismiss="modal" data-target="#registerModal">Signup</a>
                                          </div>
                                      </form>
                                          <hr className="hr-text" data-content="(OR)"></hr>                                          
                                          <button onClick={() => Auth.federatedSignIn({provider: 'Google'})} style={{ backgroundColor: "#ea4335", color: "#FFFFFF" }} class="btn btn-lg  btn-block text-capitalize" type="submit"><i class="fab fa-google mr-2"></i>Sign in with Google</button>
                                          <button onClick={() => Auth.federatedSignIn({provider: 'Facebook'})} 
                                                  style={{ backgroundColor: "#3b5998", color: "#FFFFFF" }} 
                                                  class="btn btn-lg btn-block text-capitalize" >
                                                      <i class="fab fa-facebook-f mr-2"></i>Sign in with Facebook
                                           </button>
                                  </div>
  
                              </div>
  
                          </div>
                      </div>
                  </div>
                   {/* Login Modal/Popup with Social Media Logins */}
  
  
                  {/* Register  Modal/Popup  Starts */}
                  <div class="modal fade" id="registerModal" tabindex="-1"
                      role="dialog" aria-labelledby="registerLabel" aria-hidden="true">
                      <div class="modal-dialog">
                          <div class="modal-content"  >
                              <div class="modal-header" >
                                  <h5 class="modal-title" id="loginLabel">Create New Account </h5>
                                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">&times;</span>
                                  </button>
                              </div>
                              <div class="modal-body" >
                              <div class="align-items-strech" data-aos="fade-left" id="validationSuccessDiv" style={{display:'none',color:'green'}}>
                                      Congratulations ! Your account is validated. Please SignIn to explore our services. <br/>
                                      <a href="#" data-toggle="modal" data-dismiss="modal" data-target="#loginModal">Login</a>
                              </div>
                              <div class="align-items-strech" data-aos="fade-left" id="registerSuccessDiv" style={{display:'none'}}>
                                  <form onSubmit={this.handleVerificationCode} class="php-email-form">
                                      <div style={{ color: 'red' }}>
                                              <FormErrors formerrors={this.state.errors} />
                                      </div>
                                      <p id="registerSuccessDivMsg" style={{color:'green'}}> 
                                              Your Registration is successful !

                                              Enter the verification code to activate your account received to your email.
                                      </p>
                                      <div class="form-group">
                                              <input
                                                  class="form-control"
                                                  type="text"
                                                  id="verificationCode"
                                                  aria-describedby="emailHelp"
                                                  placeholder="Enter Confirmation Code"
                                                  value={this.state.verificationCode}
                                                  onChange={this.onInputChange}
                                              />
                                          </div>
                                          <div class="form-group">
                                              <button class="btn btn-lg btn-primary btn-block text-capitalize" type="submit">Submit</button>
                                          </div>
                                          <hr></hr>
                                  </form>
                                  </div>
                                  <div class="align-items-strech" data-aos="fade-left" id="registerDiv">
                                      <form onSubmit={this.handleRegisterSubmit} class="php-email-form">
                                          <div style={{ color: 'red' }}>
                                              <FormErrors formerrors={this.state.errors} />
                                          </div>
                                          <div class="form-group">
                                              <input
                                                  class="form-control"
                                                  type="email"
                                                  id="email"
                                                  aria-describedby="emailHelp"
                                                  placeholder="Enter Email"
                                                  value={this.state.email}
                                                  onChange={this.onInputChange}
                                              />
                                          </div>
                                          <div class="form-group" >
                                                <PhoneInput
                                                    inputClass="form-control"
                                                    country={'in'}
                                                    // autoFormat={false}
                                                    value={this.state.mobile}
                                                    inputStyle={{width:'100%'}}
                                                    placeholder="Enter Mobile Number"
                                                    onChange={mobile => this.setState({ mobile })}
                                                    />
                                              {/* <input
                                                  class="form-control"
                                                  type="text"
                                                  id="mobile"
                                                  aria-describedby="emailHelp"
                                                  placeholder="Enter Mobile Number"
                                                  value={this.state.mobile}
                                                  onChange={this.onInputChange}
                                              /> */}
                                          </div>
                                          <div class="form-row">
                                          <div class="form-group col-md-6">
                                          <input
                                                  class="form-control"
                                                  type="text"
                                                  id="firstName"
                                                  placeholder="First Name"
                                                  value={this.state.firstName}
                                                  onChange={this.onInputChange}
                                              />
                                            </div>
                                            <div class="form-group col-md-6">                           
                                            <input
                                                  class="form-control"
                                                  type="text"
                                                  id="lastName"
                                                  placeholder="Last Name"
                                                  value={this.state.lastName}
                                                  onChange={this.onInputChange}
                                              />
                                          </div>
                                          </div>
                                          <div class="form-group">
                                              <input
                                                  class="form-control"
                                                  type="password"
                                                  id="password"
                                                  placeholder="Password"
                                                  value={this.state.password}
                                                  onChange={this.onInputChange}
                                              />
                                          </div>
                                          
                                          <div class="form-group">
                                              <input
                                                  class="form-control"
                                                  type="password"
                                                  id="confirmpassword"
                                                  placeholder="Confirm password"
                                                  value={this.state.confirmpassword}
                                                  onChange={this.onInputChange}
                                              />
                                          </div>
                                          {/* <div class="form-group">
                                              <a href="/forgotpassword">Forgot password?</a>
                                              
                                          </div> */}
                                          <div class="form-group">
                                              <button class="btn btn-lg btn-primary btn-block text-capitalize" type="submit">Register</button>
                                          </div>
                                          <hr className="hr-text" data-content="(OR)"></hr>
                                          <button onClick={() => Auth.federatedSignIn({provider: 'Google'})} style={{ backgroundColor: "#ea4335", color: "#FFFFFF" }} class="btn btn-lg  btn-block text-capitalize" type="submit"><i class="fab fa-google mr-2"></i>Signup with Google</button>
                                          <button onClick={() => Auth.federatedSignIn({provider: 'Facebook'})} 
                                                  style={{ backgroundColor: "#3b5998", color: "#FFFFFF" }} 
                                                  class="btn btn-lg btn-block text-capitalize" >
                                                      <i class="fab fa-facebook-f mr-2"></i>Signup with Facebook
                                           </button>

                                      </form>
                                  </div>
                              </div>
  
                          </div>
                      </div>
                  </div>
                   {/* Register Modal/Popup Ends*/}
                  
                  {/* Forgot Password  Modal/Popup */}
                  <div class="modal fade" id="forgotPasswordModal" tabindex="-1"
                      role="dialog" aria-labelledby="registerLabel" aria-hidden="true">
                      <div class="modal-dialog">
                          <div class="modal-content"  >
                              <div class="modal-header" >
                                  <h5 class="modal-title" id="loginLabel">Forgot Password ?  </h5>
                                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                      <span aria-hidden="true">&times;</span>
                                  </button>
                              </div>
                              <div class="modal-body" > 
                              <div class="align-items-strech" data-aos="fade-left" id="validationFPSuccessDiv" style={{display:'none',color:'green'}}>
                                      You Password Changed successfully. Please Sign In to explore our services. <br/>
                                      <a href="#" data-toggle="modal" data-dismiss="modal" data-target="#loginModal">Sign In</a>
                              </div>
                              <div class="align-items-strech" data-aos="fade-left" id="enterValidationFPDiv" style={{display:'none'}}>
                                  <form onSubmit={this.handleFPVerificationCode} class="php-email-form">
                                      <div style={{ color: 'red' }}>
                                              <FormErrors formerrors={this.state.errors} />
                                      </div>
                                      <p> 
                                        We have sent a password reset code by email to {this.state.email} Enter it below to reset your password.
                                      </p>
                                      <div class="form-group">
                                              <input
                                                  class="form-control"
                                                  type="text"
                                                  id="verificationCode"
                                                  aria-describedby="emailHelp"
                                                  placeholder="Code"
                                                  value={this.state.verificationCode}
                                                  onChange={this.onInputChange}
                                              />
                                          </div>

                                          <div class="form-group">
                                              <input
                                                  class="form-control"
                                                  type="password"
                                                  id="password"
                                                  placeholder="Password"
                                                  value={this.state.password}
                                                  onChange={this.onInputChange}
                                              />
                                          </div>
                                          <div class="form-group">
                                              <input
                                                  class="form-control"
                                                  type="password"
                                                  id="confirmpassword"
                                                  placeholder="Confirm password"
                                                  value={this.state.confirmpassword}
                                                  onChange={this.onInputChange}
                                              />
                                          </div>
                                          <div class="form-group">
                                              <button class="btn btn-lg btn-primary btn-block text-capitalize" type="submit">Change Password</button>
                                          </div>
                                          <hr></hr>
                                  </form>
                                  </div> 
                                  <div class="align-items-strech" data-aos="fade-left" id="forgotPasswordDiv">
                                      <form onSubmit={this.handleForgotPasswordSubmit} class="php-email-form">
                                          <div style={{ color: 'red' }}>
                                              <FormErrors formerrors={this.state.errors} />
                                          </div>
                                          <div class="form-group">
                                                Enter your Email below and we will send a message to reset your password
                                          </div>
                                          <div class="form-group">
                                              <input
                                                  class="form-control"
                                                  type="email"
                                                  id="email"
                                                  aria-describedby="emailHelp"
                                                  placeholder="Enter email"
                                                  value={this.state.email}
                                                  onChange={this.onInputChange}
                                              />
                                          </div>
                                          {/* <div class="form-group">
                                              <a href="/forgotpassword">Forgot password?</a>
                                          </div> */}
                                          <div class="form-group">
                                              <button class="btn btn-lg btn-primary btn-block text-capitalize" type="submit">Reset my password</button>
                                          </div>
                                          <hr></hr>
                                      </form>
                                  </div>
                              </div>
  
                          </div>
                      </div>
                  </div>
                   {/* Register Modal/Popup with Social Media Logins */}


            </footer>
        );

    }
}


export default Footer;
