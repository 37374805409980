function SignInFormValidation(event, state) {
    // clear all error messages
    const inputs = document.getElementsByClassName("is-danger");
    for (let i = 0; i < inputs.length; i++) {
      if (!inputs[i].classList.contains("error")) {
        inputs[i].classList.remove("is-danger");
      }
    }
    
    console.log("state.email : ");
    console.log(state.email)

    console.log("state.password : ");
    console.log(state.password)

    if (state.hasOwnProperty("email") && state.email === "") {
      document.getElementById("email").classList.add("is-danger");
      return { blankfield: true };
    }

    if (state.hasOwnProperty("password") && state.password === "") {
      document.getElementById("password").classList.add("is-danger");
      return { blankfield: true };
    }
    return;
    // if (state.hasOwnProperty("mobile") && state.mobile === "") {
    //   document.getElementById("mobile").classList.add("is-danger");
    //   return { blankfield: true };
    // }
  }
  

  export default SignInFormValidation;