import React, { Component } from 'react';
import $ from "jquery";
import Link from "react-dom";
import FormErrors from "./FormErrors";
import Validate from "./utility/FormValidation";
import { Storage } from 'aws-amplify';
import { S3Image } from 'aws-amplify-react';
Storage.configure({ level: 'private' });

class CustomerUploadDocuments extends Component {

  /**
  * ReactJS Life Cycle Method, triggered when the DOM elements are loaded and ready to render output.
  */
  componentDidMount() {
    console.log(" I AM INSIDE COMPONENT DID MOUNT LAND-SURVEY !!!!!!!!!!!!!!!!!!! ");
    //This method will be called if the user is visiting the site in thier mobile browser.
    this.convertToMobile();

    //get list of files stored by this user.
    if (localStorage.getItem("isLoggedIn")) {
      this.setState({
        userEmail: localStorage.getItem("userEmail")
      })
    };

    // console.log(Storage.list());
    Storage.list('', { level: 'private' })
      .then((result) => {
        console.log("... printing results ...");
        console.log(result);
        this.setState({
          allUserFiles: result
        })
      }
      )
      .catch(err => console.log(err));
  }

  /**
   *  This method will be used to convert the DOM elemenets when 
   * the user is visiting the site in thier mobile browser.
   */
  convertToMobile() {
    console.log("INSIDE Convert to Mobile")
    // Smooth scroll for the navigation menu and links with .scrollto classes
    //&& window.location.hostname === this.hostname
    var scrolltoOffset = $('#header').outerHeight() - 1;
    $(document).on('click', '.nav-menu a, .mobile-nav a, .scrollto', function (e) {
      if (window.location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '')
      ) {
        var target = $(this.hash);
        if (target.length) {
          e.preventDefault();

          var scrollto = target.offset().top - scrolltoOffset;

          if ($(this).attr("href") === '#header') {
            scrollto = 0;
          }

          $('html, body').animate({
            scrollTop: scrollto
          }, 1500, 'easeInOutExpo');

          if ($(this).parents('.nav-menu, .mobile-nav').length) {
            $('.nav-menu .active, .mobile-nav .active').removeClass('active');
            $(this).closest('li').addClass('active');
          }

          if ($('body').hasClass('mobile-nav-active')) {
            $('body').removeClass('mobile-nav-active');
            $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
            $('.mobile-nav-overly').fadeOut();
          }
          return false;
        }
      }
    });

    // Activate smooth scroll on page load with hash links in the url
    $(document).ready(function () {
      if (window.location.hash) {
        var initial_nav = window.location.hash;
        if ($(initial_nav).length) {
          var scrollto = $(initial_nav).offset().top - scrolltoOffset;
          $('html, body').animate({
            scrollTop: scrollto
          }, 1500, 'easeInOutExpo');
        }
      }
    });

    // Mobile Navigation
    if ($('.nav-menu').length) {
      var $mobile_nav = $('.nav-menu').clone().prop({
        class: 'mobile-nav d-lg-none'
      });
      $('body').append($mobile_nav);
      $('body').prepend('<button type="button" class="mobile-nav-toggle d-lg-none"><i class="icofont-navigation-menu"></i></button>');
      $('body').append('<div class="mobile-nav-overly"></div>');

      $(document).on('click', '.mobile-nav-toggle', function (e) {
        $('body').toggleClass('mobile-nav-active');
        $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
        $('.mobile-nav-overly').toggle();
      });

      $(document).on('click', '.mobile-nav .drop-down > a', function (e) {
        e.preventDefault();
        $(this).next().slideToggle(300);
        $(this).parent().toggleClass('active');
      });

      $(document).click(function (e) {
        var container = $(".mobile-nav, .mobile-nav-toggle");
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          if ($('body').hasClass('mobile-nav-active')) {
            $('body').removeClass('mobile-nav-active');
            $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
            $('.mobile-nav-overly').fadeOut();
          }
        }
      });
    } else if ($(".mobile-nav, .mobile-nav-toggle").length) {
      $(".mobile-nav, .mobile-nav-toggle").hide();
    }


    // Navigation active state on scroll
    var nav_sections = $('section');
    var main_nav = $('.nav-menu, .mobile-nav');

    $(window).on('scroll', function () {
      var cur_pos = $(this).scrollTop() + 200;

      nav_sections.each(function () {
        var top = $(this).offset().top,
          bottom = top + $(this).outerHeight();

        if (cur_pos >= top && cur_pos <= bottom) {
          if (cur_pos <= bottom) {
            main_nav.find('li').removeClass('active');
          }
          main_nav.find('a[href="#' + $(this).attr('id') + '"]').parent('li').addClass('active');
        }
        if (cur_pos < 300) {
          $(".nav-menu ul:first li:first").addClass('active');
        }
      });
    });

    // Toggle .header-scrolled class to #header when page is scrolled
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('#header').addClass('header-scrolled');
      } else {
        $('#header').removeClass('header-scrolled');
      }
    });

    if ($(window).scrollTop() > 100) {
      $('#header').addClass('header-scrolled');
    }

    // Back to top button
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('.back-to-top').fadeIn('slow');
      } else {
        $('.back-to-top').fadeOut('slow');
      }
    });

    $('.back-to-top').click(function () {
      $('html, body').animate({
        scrollTop: 0
      }, 1500, 'easeInOutExpo', function () {
        $(".nav-menu ul:first li:first").addClass('active');
      });

      return false;
    });

  }

  state = {
    file: "",
    userEmail: "",
    allUserFiles: [],
    uploadFilesList: [],
    errors: {
      cognito: null,
      blankfield: false,
      passwordmatch: false
    }
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
        passwordmatch: false
      }
    });
  }

  //Function used to upload the files
  uploadFile = () => {

    console.log("inside handle submit upload file form");

    console.log("list of files in DOM");

    // Validate File Size and Type here 
    this.clearErrorState();

    // AWS Storage S3 integration here
    // // try { 
    console.log(" Storage Function BEing called !!! ! ! !  ! ");
    console.log(this.state);
    console.log(this.state.uploadFilesList);
    // console.log(this.state.file);
    let isError = false;
    //Loop Through list of Files stored in State and upload using Amplify Storrage Class
    this.state.uploadFilesList.map((fileItem) => {
      Storage.put(fileItem.name, fileItem)
        .then(() => {
          console.log(fileItem.name + " >>> uploaded File Successfully  !!! ! ! !  ! ");
        }
        ).catch(err => {
          console.log('ERORO OCCURRED !!!!! ', err);
          this.setState({
            errors: { ...this.state.errors, cognito: err }
          });
          isError = true;
          return;
        }
        )
    })

    //Reset files List in State
    if (isError) {
      this.setState({
        uploadFilesList: []
      })
    }

    //get the latest uploaded from S3 and set to State Variable "allUserFiles"
    Storage.list('', { level: 'private' })
      .then((result) => {
        console.log("... printing results ...");
        console.log(result);
        this.setState({
          allUserFiles: result
        })
      }
      ).catch(err => console.log(err));


  };

  onInputChange = event => {
    this.setState({
      errors: { ...this.state.errors, cognito: { "message": "" } }
    });

    this.setState({
      [event.target.id]: event.target.value
    });
    const file = event.target.files[0];
    console.log("inside file on change .....");
    console.log("event.target.id : ");
    console.log(file);
    console.log(event.target.id);
    console.log(file.size);
    console.log(file.name);
    console.log(file.type);


    //if the file type is not valid, throw error
    if (file.type === "image/jpeg" ||
      file.type === "image/jpg" ||
      file.type === "image/png" ||
      file.type === "application/pdf") {
      console.log('VALID FILE !!! ');
    } else {
      console.log('file TYPE NOT NOT correct');
      this.setState({
        errors: { ...this.state.errors, cognito: { "message": "Invalid file type ! : '" + file.name + "'" } }
      });
      return;
    }

    //if the file size is less than 2MB 
    if (file.size < 2097152) {
      this.state.uploadFilesList.push(file);
      document.getElementById(event.target.id).classList.remove("is-danger");
    } else {
      console.log('file size NOT NOT correct');
      this.setState({
        errors: { ...this.state.errors, cognito: { "message": "File Size should be less than 2MB" } }
      });
      return;
    }
  }

  selectFile = async file => {
    //const signed = await Storage.get(file.key, { download: true })

    Storage.get(file.key, { expires: 60 })
      .then((result) => {

        console.log(result)
      }
      )
      .catch(err => console.log(err));

    // console.log("signed");
    // console.log(signed);
    // this.setState({ file: signed })
  }

  deleteFile= (event)  => {
    console.log("inside deleteFile ....");
    console.log(event); 
  }


  render() {
    return (
      <main id="main">
        <section id="faq" class="faq">
          <div class="container" style={{ width: '50%', border: "0px red solid" }}>
            <h1>Upload Documents</h1>
            <div style={{ color: 'red' }}>
              <FormErrors formerrors={this.state.errors} />
            </div>

            <div class="form-group">
              <span>Sale Deed </span>
              <input
                class="form-control"
                type="file"
                id="saleDeed"
                onChange={this.onInputChange}
              />
            </div>
            <div class="form-group">
              <span>Layout Plan </span>
              <input
                class="form-control"
                type="file"
                id="layoutPlan"
                onChange={this.onInputChange}
              />
            </div>
            <div class="form-group">
              <span>Aadhar Card </span>
              <input
                class="form-control"
                type="file"
                id="aadharCard"
                onChange={this.onInputChange}
              />
            </div>
            <div class="form-group">
              <button
                class="btn btn-lg btn-primary btn-block text-capitalize"
                type="submit"
                onClick={this.uploadFile}>Upload</button>
            </div>
            <hr></hr>
            <div>
              <p style={{ fontSize: "12px", color: 'red' }}><b>Important Note : </b>
                <ul>
                  <li>File Size should be less than 2MB</li>
                  <li>Valid file formats jpeg/jpg/png/pdf </li>
                </ul>
              </p>
            </div>
            {/* <img src={this.state.fileUrl}/> */}
            <div style={{ width: '100%', margin: '0 auto' }}>
              <table id="example" class="table table-striped table-bordered"
                style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th><nobr>SNO</nobr></th>
                    <th><nobr>File Type</nobr></th>
            
                    <th><nobr>File Name</nobr></th>
                    <th><nobr>Date Uploaded</nobr></th>
                    <th><nobr>Action</nobr> </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.allUserFiles.map((file, i) => (
                    <tr>
                      <td>{i + 1}</td>
                      <td>{i + 1}</td>
                      <td>
                        <span onClick={() => this.selectFile(file)}>{file.key}
                        </span>
                      </td>
                      <td>Date</td>
                      <td> DELETE
                        {/* <Link > Delete File </> */}
                  {/* <Link onClick={() => this.deleteFile(file)}>Delete</Link> */}
                      </td>
                    </tr>
                  ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </main>
    );
  }
}

export default CustomerUploadDocuments;