import React, { Component } from "react";
import FormErrors from "./FormErrors";
import Validate from "./utility/FormValidation";
import { Auth } from 'aws-amplify';
import { connect } from 'react-redux';
import { loginAction } from './actions/index.js'
import store from './store';
import {Redirect} from 'react-router-dom';
const userAuthState = {
  isLoggedIn: false,
  userEmail: "",
  userName: ""
}



class Login extends Component {

  state = {
    //        username: "",
    verificationCode: "",
    email: "",
    password: "",
    isLoggedIn: false,
    errors: {
        cognito: null,
        blankfield: false
    }
};


onInputChange = event => {
  this.setState({
      [event.target.id]: event.target.value
  });
  //document.getElementById(event.target.id).classList.remove("is-danger");
};

clearErrorState = () => {
  this.setState({
      errors: {
          cognito: null,
          blankfield: false
      }
  });
};


  handleLoginSubmit = async event => {
    event.preventDefault();
    console.log("Inside Login Function handle Submit  !!!!");

    console.log("store.getState()......");
    console.log(store.getState());

    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
      return;
    }

    // AWS Cognito integration here
    console.log(" CALLING COGNITO !!! ! ! !  ! ");
    console.log("........1.....email. : ");
    console.log(this.state.email)

    console.log("........1.....password. : ");
    console.log(this.state.password)

    try {
      const signInResponse = await Auth.signIn(this.state.email, this.state.password);
      console.log(signInResponse);
      console.log(this.props);

      this.setState({
        isLoggedIn: true
      });

      
      //get the current User Session from AWS Cognito
      const currentSession = await Auth.currentSession();
      
      console.log(" Printing currentSession  payload: email ==> ");
      console.log(currentSession.getIdToken().payload.email);
      console.log(currentSession.getIdToken().payload.email_verified);
      console.log(currentSession.getIdToken().getJwtToken());
      
      //set the variables in localstorage
      localStorage.setItem("userEmail",currentSession.getIdToken().payload.email);
      localStorage.setItem("isLoggedIn",true);
      localStorage.setItem("userToken",currentSession.getIdToken().getJwtToken());

      //set the user Authentication details her
      // userAuthState.isLoggedIn = true;
      // userAuthState.userEmail = this.state.email;
      // userAuthState.userName = this.state.email;
      // userAuthState.userAuthToken = currentSession;

      //userAuthState.userId = 1;
      //dispatching the action to store the user authentication state
      // this.props.loginUser(userAuthState);

      console.log("this.props.isLoggedIn ==> : ");
      console.log(this.props.isLoggedIn);

      //upon successful validation, forward the user to the home page.
      window.location.href = "/customer-home-page";

    } catch (error) {
      console.log("ERROR OCCURRED !!! ! ! !  ! ");
      this.setState({
        errors: { ...this.state.errors, cognito: error }
      });

    }


  };


  render() {
    return (
     localStorage.getItem("isLoggedIn") ) ? 
     (<Redirect  to="/customer-home-page"/> ) :      
     (
    <main id="main">
    {/* Banner Section Starts */}
    <section id="faq"  className="faq">
      <div className="container">
        <div class="row" >
          <div className="col-lg-6" data-aos="zoom-in">
          <form onSubmit={this.handleLoginSubmit} class="php-email-form">
              <div style={{ color: 'red' }}>
                <FormErrors formerrors={this.state.errors} />
              </div>
              <div class="form-group">
                <input
                  class="form-control"
                  type="email"
                  id="email"
                  placeholder="Enter email"
                  value={this.state.email}
                  onChange={this.onInputChange}
                />
              </div>
              <div class="form-group">
                <input
                  class="form-control"
                  type="password"
                  id="password"
                  placeholder="Password"
                  value={this.state.password}
                  onChange={this.onInputChange}
                />
              </div>

              <div class="form-group">
                <button class="btn btn-lg btn-primary btn-block text-capitalize"
                  type="submit">Sign in</button>
              </div>

              <div class="form-group">
                  <input type="checkbox" id="customCheck1" />
              &nbsp;<label>Remember password</label>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <a href="#" onClick={this.showForgotPasswordForm} >Forgot Password ? </a> <br />
              </div>
              <div class="form-group">
                   No Account ?
                   &nbsp;&nbsp;&nbsp; <a href="/register">Signup</a>
              </div>
            </form>
        
        
          </div>
          <div className="col-lg-6 d-flex flex-column justify-contents-center" data-aos="fade-left">
              <button style={{ backgroundColor: "#ea4335", color: "#FFFFFF" }} class="btn btn-lg  btn-block text-capitalize" type="submit"><i class="fab fa-google mr-2"></i>Sign in with Google</button>
              <button style={{ backgroundColor: "#3b5998", color: "#FFFFFF" }} class="btn btn-lg btn-block text-capitalize" type="submit"><i class="fab fa-facebook-f mr-2"></i>Sign in with Facebook</button>
          </div>
        </div>
      </div>
    </section>
  </main>

    )
  }

}

const mapStateToProps = (state) => ({
  isLoggedIn: state.userAuth.isLoggedIn,
  userEmail: state.userAuth.userEmail,
  userName: state.userAuth.userName,
  userAuthToken: state.userAuth.userAuthToken
});

const mapDispatchToProps = dispatch => ({
  loginUser: (payload) => dispatch(loginAction(userAuthState))
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
