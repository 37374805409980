import React, { Component } from 'react';
import $ from "jquery";
import FormErrors from "./FormErrors";
import Validate from "./utility/FormValidation";
import {Auth} from 'aws-amplify';
import {connect} from 'react-redux';
import {getLoggedInUser} from './actions/index'
import store from './store';
import {Redirect} from 'react-router-dom';


const userAuthState = {
  isLoggedIn: false,
  userEmail: "",
  userName: ""
}

class CustomerHome extends Component {

  

   /**
   * ReactJS Life Cycle Method, triggered when the DOM elements are loaded and ready to render output.
   */
  componentDidMount() {

    console.log(" I AM INSIDE COMPONENT DID MOUNT CUSTOMER-HOME PAGE -this.props.isLoggedIn !!!!!!!!!!!!!!!!!!! ");
    // this.props.getUser();
    
    console.log("1111111111111userToken : " + localStorage.getItem("userToken"))
    console.log("1111111111111userEmai : " + localStorage.getItem("userEmail"))
    console.log("1111111111111 isLoggedIn : " + localStorage.getItem("isLoggedIn"))

    console.log("localStorage.getItem USER ........");
    console.log(localStorage.getItem("user"));

    //This method will be called if the user is visiting the site in thier mobile browser.
    this.convertToMobile();
  }
 
  /**
   *  This method will be used to convert the DOM elemenets when 
   * the user is visiting the site in thier mobile browser.
   */
  convertToMobile() {
    console.log("INSIDE Convert to Mobile")
    // Smooth scroll for the navigation menu and links with .scrollto classes
    //&& window.location.hostname === this.hostname
    var scrolltoOffset = $('#header').outerHeight() - 1;
    $(document).on('click', '.nav-menu a, .mobile-nav a, .scrollto', function (e) {
      if (window.location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '')
      ) {
        var target = $(this.hash);
        if (target.length) {
          e.preventDefault();

          var scrollto = target.offset().top - scrolltoOffset;

          if ($(this).attr("href") === '#header') {
            scrollto = 0;
          }

          $('html, body').animate({
            scrollTop: scrollto
          }, 1500, 'easeInOutExpo');

          if ($(this).parents('.nav-menu, .mobile-nav').length) {
            $('.nav-menu .active, .mobile-nav .active').removeClass('active');
            $(this).closest('li').addClass('active');
          }

          if ($('body').hasClass('mobile-nav-active')) {
            $('body').removeClass('mobile-nav-active');
            $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
            $('.mobile-nav-overly').fadeOut();
          }
          return false;
        }
      }
    });

    // Activate smooth scroll on page load with hash links in the url
    $(document).ready(function () {
      if (window.location.hash) {
        var initial_nav = window.location.hash;
        if ($(initial_nav).length) {
          var scrollto = $(initial_nav).offset().top - scrolltoOffset;
          $('html, body').animate({
            scrollTop: scrollto
          }, 1500, 'easeInOutExpo');
        }
      }
    });

    // Mobile Navigation
    if ($('.nav-menu').length) {
      var $mobile_nav = $('.nav-menu').clone().prop({
        class: 'mobile-nav d-lg-none'
      });
      $('body').append($mobile_nav);
      $('body').prepend('<button type="button" className="mobile-nav-toggle d-lg-none"><i className="icofont-navigation-menu"></i></button>');
      $('body').append('<div className="mobile-nav-overly"></div>');

      $(document).on('click', '.mobile-nav-toggle', function (e) {
        $('body').toggleClass('mobile-nav-active');
        $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
        $('.mobile-nav-overly').toggle();
      });

      $(document).on('click', '.mobile-nav .drop-down > a', function (e) {
        e.preventDefault();
        $(this).next().slideToggle(300);
        $(this).parent().toggleClass('active');
      });

      $(document).click(function (e) {
        var container = $(".mobile-nav, .mobile-nav-toggle");
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          if ($('body').hasClass('mobile-nav-active')) {
            $('body').removeClass('mobile-nav-active');
            $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
            $('.mobile-nav-overly').fadeOut();
          }
        }
      });
    } else if ($(".mobile-nav, .mobile-nav-toggle").length) {
      $(".mobile-nav, .mobile-nav-toggle").hide();
    }


    // Navigation active state on scroll
    var nav_sections = $('section');
    var main_nav = $('.nav-menu, .mobile-nav');

    $(window).on('scroll', function () {
      var cur_pos = $(this).scrollTop() + 200;

      nav_sections.each(function () {
        var top = $(this).offset().top,
          bottom = top + $(this).outerHeight();

        if (cur_pos >= top && cur_pos <= bottom) {
          if (cur_pos <= bottom) {
            main_nav.find('li').removeClass('active');
          }
          main_nav.find('a[href="#' + $(this).attr('id') + '"]').parent('li').addClass('active');
        }
        if (cur_pos < 300) {
          $(".nav-menu ul:first li:first").addClass('active');
        }
      });
    });

    // Toggle .header-scrolled class to #header when page is scrolled
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('#header').addClass('header-scrolled');
      } else {
        $('#header').removeClass('header-scrolled');
      }
    });

    if ($(window).scrollTop() > 100) {
      $('#header').addClass('header-scrolled');
    }

    // Back to top button
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('.back-to-top').fadeIn('slow');
      } else {
        $('.back-to-top').fadeOut('slow');
      }
    });

    $('.back-to-top').click(function () {
      $('html, body').animate({
        scrollTop: 0
      }, 1500, 'easeInOutExpo', function () {
        $(".nav-menu ul:first li:first").addClass('active');
      });

      return false;
    });

  }

  state = {
    isLoggedIn: false,
    email: "",
    password: "",
    confirmpassword: "",
    tabClassName : "tab-pane active show",
    errors: {
      cognito: null,
      blankfield: false,
      passwordmatch: false
    }
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
        passwordmatch: false
      }
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    console.log("inside handle submit registration form");

    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
    }

    // AWS Cognito integration here

    try { 
      console.log(" CALLING COGNITO !!! ! ! !  ! ");
      console.log("........1.....email. : ");
      console.log(this.state.email)

      console.log("........1.....password. : ");
      console.log(this.state.password)


      const signUpResponse = await Auth.signUp(
            {
              username:this.state.email,
              password:this.state.password,
               attribute : {
                   email : this.state.email
               } 
            } );
            console.log(signUpResponse);
            this.props.history.push("/land-survey");

    } catch (error ) {
        console.log("ERROR OCCURRED !!! ! ! !  ! ");
        this.setState({
          errors: { ...this.state.errors,cognito:error }
        });
    }

  };

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  }

  render() {
    return (
      !localStorage.getItem("isLoggedIn")  ? 
     (<Redirect  to="/"/> ):      
     
      <main id="main">
                {/* Features Section Starts */}
                <section id="features" className="features">
          <div className="container">
            <h3>Building Sale Agreement Franking Service</h3>
            <div className="row">
              <div className="col-lg-6 mt-2 mb-tg-0 order-2 order-lg-1">
                <ul className="nav nav-tabs flex-column">
                  <li className="nav-item" data-aos="fade-up">
                    <a className="nav-link active show" data-toggle="tab" href="#tab-1">
                      <h4>My Orders </h4>
                    </a>
                  </li>
                  <li className="nav-item mt-2" data-aos="fade-up" data-aos-delay="100">
                    <a className="nav-link" data-toggle="tab" href="#tab-2">
                      <h4>Upload Pending Documents  </h4>
                    </a>
                  </li>
                  <li className="nav-item mt-2" data-aos="fade-up" data-aos-delay="100">
                    <a className="nav-link" data-toggle="tab" href="#tab-3">
                      <h4>Update Profile </h4>
                    </a>
                  </li>
                  <li className="nav-item mt-2" data-aos="fade-up" data-aos-delay="100">
                    <a className="nav-link" data-toggle="tab" href="#tab-3">
                      <h4>Miscellenous </h4>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-6 order-1 order-lg-2" data-aos="zoom-in">
                <div className="tab-content">
                  <div className={this.state.tabClassName} id="tab-1">
                    <div className="row">
                      <div className="col-sm" style={{ textAlign: "left" }}>
                        <p style={{ color: "#009cea" }}><i className="icofont-google-map"></i>Professional Team<br /></p>
                        <p style={{ fontSize: "small" }}>
                          Our team of experts have more than 10 years of experience in this field.
                          They will make sure your interests are taken care while doing this transaction.
                   </p>
                        <br />
                        <p style={{ color: "#009cea" }}><i className="icofont-rupee"></i> Transparent Pricing<br /></p>
                        <p style={{ fontSize: "small" }}>
                          Our services will ensures transparent pricing, you will be paying what is being done actually. <br />
                        </p>
                      </div>
                      <div className="col-sm" style={{ textAlign: "left" }}>
                        <p style={{ color: "#009cea" }}><i className="icofont-tick-boxed"></i> Data Accuracy<br /></p>
                        <p style={{ fontSize: "small" }}>
                          Our professional team will prepare the sale agreement in format approved by govt.
                  </p>
                        <br /><br />
                        <p style={{ color: "#009cea" }}><i className="icofont-ssl-security"></i>&nbsp;Delivery <br /></p>
                        <p style={{ fontSize: "small" }}>
                          Building Sale Agreement with franking will be done in 24 - 48 hrs time depending on the documents provided.
                  </p>
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane" id="tab-2">
                    <div className="row">
                      <div className="col-sm" >
                        <p style={{ color: "#009cea" }}><i className="icofont-listine-dots"></i>&nbsp;Choose the service<br /></p>
                        <p style={{ fontSize: "small" }}>
                          Choose the service you are looking from our list of services.
                          Once you choose the service, you have to upload documents related to the building.
                     </p>
                        <br /><br />
                        <p style={{ color: "#009cea" }}><i className="icofont-package"></i> Combo Package<br /></p>
                        <p style={{ fontSize: "small" }}>
                          We have combo packages for your plot, please refer to our pricing list for getting discount.
                     </p>
                      </div>
                      <div className="col-sm" >
                        <p style={{ color: "#009cea" }}><i className="icofont-pay"></i> Payment <br /></p>
                        <p style={{ fontSize: "small" }}>
                          Upon selecting your package proceed to our secured payment process.
                          Upon succesful payment, your order will be created and our team will be in touch with you.
                     </p>
                        <br />
                        <p style={{ color: "#009cea" }}><i className="icofont-ssl-security"></i>&nbsp;Deliverables <br /></p>
                        <p style={{ fontSize: "small" }}>
                          The Sale Agreement copy will be delivered as per Govt/Bank approved format.
                    </p>
                      </div>

                    </div>
                  </div>
                  <div className="ui-group-buttons">
                    {/* <!-- Button trigger modal --> */}
                    <button type="button" className="btn btn-primary btn-lg" data-toggle="modal" data-target="#exampleModal">Enquire Now</button>
                  </div>
                  {/* <!-- <div className='pm-button'>
                <a href='https://www.payumoney.com/paybypayumoney/#/6331204134B2D38B89CCD5BF509CF00E'>
                <img src='https://www.payumoney.com/media/images/payby_payumoney/new_buttons/21.png' />
              </a>
              </div>  --> */}
                </div>
              </div>

            </div>

          </div>
        </section>
        {/* Features Section Ends */}
      <section id="faq" className="faq">
        <div className="container" style={{width:'50%', border:"1px red solid"}}>
          <h1>Register</h1>
          <div style={{color:'red'}}>
             I AM IN CUSOTMER HOME PAGE !!!!  {localStorage.getItem("userEmail") } 
             <div className="align-items-strech" data-aos="fade-left" id="registerDiv">
                                      <form onSubmit={this.handleRegisterSubmit} className="php-email-form">
                                          <div style={{ color: 'red' }}>
                                              <FormErrors formerrors={this.state.errors} />
                                          </div>
                                          <div className="form-group">
                                              <input
                                                  className="form-control"
                                                  type="email"
                                                  id="email"
                                                  aria-describedby="emailHelp"
                                                  placeholder="Enter email"
                                                  value={this.state.email}
                                                  onChange={this.onInputChange}
                                              />
                                          </div>
                                          <div className="form-group">
                                              <input
                                                  className="form-control"
                                                  type="firstName"
                                                  id="firstName"
                                                  aria-describedby="emailHelp"
                                                  placeholder="Enter First Name"
                                                  value={this.state.firstName}
                                                  onChange={this.onInputChange}
                                              />
                                          </div>
                                          <div className="form-group">
                                              <input
                                                  className="form-control"
                                                  type="lastName"
                                                  id="lastName"
                                                  aria-describedby="emailHelp"
                                                  placeholder="Enter Last Name"
                                                  value={this.state.lastName}
                                                  onChange={this.onInputChange}
                                              />
                                          </div>
                                          <div className="form-group">
                                              <input
                                                  className="form-control"
                                                  type="textArea"
                                                  id="address"
                                                  aria-describedby="emailHelp"
                                                  placeholder="Enter Address"
                                                  value={this.state.address}
                                                  onChange={this.onInputChange}
                                              />
                                          </div>
                                          <div className="form-group">
                                              <input
                                                  className="form-control"
                                                  type="password"
                                                  id="password"
                                                  placeholder="Password"
                                                  value={this.state.password}
                                                  onChange={this.onInputChange}
                                              />
                                          </div>
                                          <div className="form-group">
                                              <input
                                                  className="form-control"
                                                  type="password"
                                                  id="confirmpassword"
                                                  placeholder="Confirm password"
                                                  value={this.state.confirmpassword}
                                                  onChange={this.onInputChange}
                                              />
                                          </div>
                                          {/* <div className="form-group">
                                              <a href="/forgotpassword">Forgot password?</a>
                                          </div> */}
                                          <div className="form-group">
                                              <button className="btn btn-lg btn-primary btn-block text-capitalize" type="submit">Register</button>
                                          </div>
                                          <hr></hr>
                                      </form>
                                  </div>

          </div>

            
        </div>
      </section>
      </main>
    )
  }
}

const mapStateToProps = (state) => ({
	isLoggedIn: state.userAuth.isLoggedIn,
	userEmail: state.userAuth.userEmail,
	userName: state.userAuth.userName,
	userAuthToken: state.userAuth.userAuthToken 
});

const mapDispatchToProps = dispatch => ({
	getUser: (payload) => dispatch(getLoggedInUser(userAuthState))
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomerHome);

//export default CustomerHome;