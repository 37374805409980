import React from 'react';
import $ from "jquery";
// import logo from './logo.svg';
// import './App.css';

class HomeLoan extends React.Component {

  /**
  /**
   * ReactJS Life Cycle Method, triggered when the DOM elements are loaded and ready to render output.
   */
  componentDidMount() {
    console.log(" I AM INSIDE COMPONENT DID MOUNT LAND-SURVEY !!!!!!!!!!!!!!!!!!! ");
    //This method will be called if the user is visiting the site in thier mobile browser.
    this.convertToMobile();
  }

  /**
   *  This method will be used to convert the DOM elemenets when 
   * the user is visiting the site in thier mobile browser.
   */
  convertToMobile() {
    console.log("INSIDE Convert to Mobile")
    // Smooth scroll for the navigation menu and links with .scrollto classes
    //&& window.location.hostname === this.hostname
    var scrolltoOffset = $('#header').outerHeight() - 1;
    $(document).on('click', '.nav-menu a, .mobile-nav a, .scrollto', function (e) {
      if (window.location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '')
      ) {
        var target = $(this.hash);
        if (target.length) {
          e.preventDefault();

          var scrollto = target.offset().top - scrolltoOffset;

          if ($(this).attr("href") === '#header') {
            scrollto = 0;
          }

          $('html, body').animate({
            scrollTop: scrollto
          }, 1500, 'easeInOutExpo');

          if ($(this).parents('.nav-menu, .mobile-nav').length) {
            $('.nav-menu .active, .mobile-nav .active').removeClass('active');
            $(this).closest('li').addClass('active');
          }

          if ($('body').hasClass('mobile-nav-active')) {
            $('body').removeClass('mobile-nav-active');
            $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
            $('.mobile-nav-overly').fadeOut();
          }
          return false;
        }
      }
    });

    // Activate smooth scroll on page load with hash links in the url
    $(document).ready(function () {
      if (window.location.hash) {
        var initial_nav = window.location.hash;
        if ($(initial_nav).length) {
          var scrollto = $(initial_nav).offset().top - scrolltoOffset;
          $('html, body').animate({
            scrollTop: scrollto
          }, 1500, 'easeInOutExpo');
        }
      }
    });

    // Mobile Navigation
    if ($('.nav-menu').length) {
      var $mobile_nav = $('.nav-menu').clone().prop({
        class: 'mobile-nav d-lg-none'
      });
      $('body').append($mobile_nav);
      $('body').prepend('<button type="button" class="mobile-nav-toggle d-lg-none"><i class="icofont-navigation-menu"></i></button>');
      $('body').append('<div class="mobile-nav-overly"></div>');

      $(document).on('click', '.mobile-nav-toggle', function (e) {
        $('body').toggleClass('mobile-nav-active');
        $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
        $('.mobile-nav-overly').toggle();
      });

      $(document).on('click', '.mobile-nav .drop-down > a', function (e) {
        e.preventDefault();
        $(this).next().slideToggle(300);
        $(this).parent().toggleClass('active');
      });

      $(document).click(function (e) {
        var container = $(".mobile-nav, .mobile-nav-toggle");
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          if ($('body').hasClass('mobile-nav-active')) {
            $('body').removeClass('mobile-nav-active');
            $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
            $('.mobile-nav-overly').fadeOut();
          }
        }
      });
    } else if ($(".mobile-nav, .mobile-nav-toggle").length) {
      $(".mobile-nav, .mobile-nav-toggle").hide();
    }


    // Navigation active state on scroll
    var nav_sections = $('section');
    var main_nav = $('.nav-menu, .mobile-nav');

    $(window).on('scroll', function () {
      var cur_pos = $(this).scrollTop() + 200;

      nav_sections.each(function () {
        var top = $(this).offset().top,
          bottom = top + $(this).outerHeight();

        if (cur_pos >= top && cur_pos <= bottom) {
          if (cur_pos <= bottom) {
            main_nav.find('li').removeClass('active');
          }
          main_nav.find('a[href="#' + $(this).attr('id') + '"]').parent('li').addClass('active');
        }
        if (cur_pos < 300) {
          $(".nav-menu ul:first li:first").addClass('active');
        }
      });
    });

    // Toggle .header-scrolled class to #header when page is scrolled
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('#header').addClass('header-scrolled');
      } else {
        $('#header').removeClass('header-scrolled');
      }
    });

    if ($(window).scrollTop() > 100) {
      $('#header').addClass('header-scrolled');
    }

    // Back to top button
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('.back-to-top').fadeIn('slow');
      } else {
        $('.back-to-top').fadeOut('slow');
      }
    });

    $('.back-to-top').click(function () {
      $('html, body').animate({
        scrollTop: 0
      }, 1500, 'easeInOutExpo', function () {
        $(".nav-menu ul:first li:first").addClass('active');
      });

      return false;
    });

  }


  render() {
    return (
      <main id="main">
        {/* Banner Section Starts */}
        <section id="hero">
          <div className="container">
            <div className="row" >
              <div
                className="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-right"
                data-aos="fade-up" >
                <div className="content pt-4 pt-lg-0" >
                  <i className="icofont-tick-boxed"></i>&nbsp;Home Purchase Loan<br/>
                  <i className="icofont-tick-boxed"></i>&nbsp;Home Construction Loan<br/>
                  <i className="icofont-tick-boxed"></i>&nbsp;Home Extension Loan<br/><br/><br/><br/>
                  <a href="#about" className="btn-get-started scrollto">Get Started</a>
                </div>
              </div>
              <div
                className="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-right"
                data-aos="fade-up" >
                <div className="content pt-4 pt-lg-0" >
                    <i className="icofont-tick-boxed"></i>&nbsp;Home Conversion Loan<br/>
                    <i className="icofont-tick-boxed"></i>&nbsp;House Improvement Loan<br/>
                    <i className="icofont-tick-boxed"></i>&nbsp;NRI Home Loan<br/>
                    <i className="icofont-tick-boxed"></i>&nbsp;Land Purchase Loan<br/>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Banner Section Ends */}

        {/* Features Section Starts */}
        <section id="features" className="features">

        <div className="container">
        <h3>Property Loan Service</h3>
        <div className="row">
          <div className="col-lg-6 mt-2 mb-tg-0 order-2 order-lg-1">
            <ul className="nav nav-tabs flex-column">
              <li className="nav-item" data-aos="fade-up">
                <a className="nav-link active show" data-toggle="tab" href="#tab-1">
                  <h4>Why PropWindow ? </h4>
                </a>
              </li>
              <li className="nav-item mt-2" data-aos="fade-up" data-aos-delay="100">
                <a className="nav-link" data-toggle="tab" href="#tab-2">
                  <h4>How to book our Property Loan service ? </h4>
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-6 order-1 order-lg-2" data-aos="zoom-in">
            <div className="tab-content">
              <div className="tab-pane active show" id="tab-1">
                <div className="row">
                <div className="col-sm" style={{ textAlign: "left" }}>
                   <p style={{ color: "#009cea" }}><i className="icofont-google-map"></i> Experienced Team<br/></p>
                   <p style={{ fontSize: "small" }}>
                    Once you are availed our services, our experienced will ensure that you will receive necessary property loan you are looking for.
                  </p>
                  <br/><br/>
                   <p style={{ color: "#009cea" }}><i className="icofont-home"></i> Home  Loan <br/></p>
                   <p style={{ fontSize: "small" }}>
                     If you are looking for any kind of home loan we will help you. 
                     <ul style={{ fontSize: "small" }}>
                      <li>Home/Flat Purchase Loan</li>
                      <li>Home/Flat construction loan </li>
                      <li>Home Extension loans</li>
                      <li>Home Improvement loans</li>
                    </ul> 
                   </p>
                    </div>
                <div className="col-sm" style={{ textAlign: "left" }}>
                  <p style={{ color: "#009cea" }}><i className="icofont-world"></i> NRI Home Loan<br/></p>
                  <p style={{ fontSize: "small" }}>
                     A NRI Home Loan is for Non-Resident Indians who wants to buy a property in India. 
                     Its a tediuos process, our experienced team of professionals will help you getting the loans without any hassels.
                  </p>
                  <br/>
                  <p style={{ color: "#009cea" }}><i className="icofont-map"></i>&nbsp; Land Purchase Loan <br/></p>
                  <p style={{ fontSize: "small" }}>
                    Land Purchase loans are funded by nationalized/prive banks or non-banking financial companies (NBFCs)
                    to buy a plot or land. 
                    You will receive 80-85% of the price of the plot or land depending on the documentation. 
                  </p>
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="tab-2">
                <div className="row">
                  <div className="col-sm" >
                     <p style={{ color: "#009cea" }}><i className="icofont-listine-dots"></i>&nbsp;Choose the service.<br/></p>
                     <p style={{ fontSize: "small" }}>
                       Choose the service you are looking from our list of services. Once you choose the service, you have to upload documents related to the open plot.
                     </p>
                     <br/><br/>
                     <p style={{ color: "#009cea" }}><i className="icofont-package"></i> Combo Package<br/></p>
                     <p style={{ fontSize: "small" }}>
                      We have combo packages for your plot, please refer to our pricing list for getting discount.
                     </p>
                  </div>
                  <div className="col-sm" >
                    <p style={{ color: "#009cea" }}><i className="icofont-pay"></i> Payment <br/></p>
                    <p style={{ fontSize: "small" }}>
                      Upon selecting your package proceed to our secured payment process. Upon succesful payment, your order will be created and our team will be in touch with you.
                     </p>
                    <br/>
                    <p style={{ color: "#009cea" }}><i className="icofont-ssl-security"></i>&nbsp;Deliverables <br/></p>
                    <p style={{ fontSize: "small" }}>
                      Once the land survery is over, our experienced surveying team will deliver the Two dimensionsal drawings of the plot with any deviations found. 
                    </p>
                    </div>
                    
                  </div>
              </div>
              <div className="ui-group-buttons">
                {/* <!-- Button trigger modal --> */}
                <button type="button" className="btn btn-primary btn-lg" data-toggle="modal" data-target="#exampleModal">Enquire Now</button>
              </div>
              {/* <!-- <div className='pm-button'>
                <a href='https://www.payumoney.com/paybypayumoney/#/6331204134B2D38B89CCD5BF509CF00E'>
                <img src='https://www.payumoney.com/media/images/payby_payumoney/new_buttons/21.png' />
              </a>
              </div>  --> */}
            </div>
          </div>
          
        </div>

      </div>


        </section>
        {/* Features Section Ends */}

        {/* FAQ Section Ends */}
        <section id="faq" className="faq">
          <div className="container">

            <div className="section-title" data-aos="fade-up">
              <h2>Frequently Asked Questions</h2>
            </div>

            <ul className="faq-list">

              <li data-aos="fade-up">
                <a data-toggle="collapse" href="#faq1" className=""> What is Topographic Survey ? <i className="icofont-simple-up"></i></a>
                <div id="faq1" className="collapse show" data-parent=".faq-list">
                  <p>
                    A Topographic Survey is a survey that gathers data about the elevation of points on a piece of land and presents them as contour lines on a plot. The purpose of a topographic survey is to collect survey data about the natural and man-made features of the land, as well as its elevations.              </p>
                </div>
              </li>
              <li data-aos="fade-up" data-aos-delay="500">
                <a data-toggle="collapse" className="collapsed" href="#faq2">Which Properties a surveyor can survey  ? <i className="icofont-simple-up"></i></a>
                <div id="faq2" className="collapse" data-parent=".faq-list">
                  <p> Professional surveryors can survery any of the following properties.
                <ul>
                      <li>Residential</li>
                      <li>Commercial </li>
                      <li>Agricultural Land </li>
                    </ul>
                  </p>
                </div>
              </li>

              <li data-aos="fade-up" data-aos-delay="100">
                <a data-toggle="collapse" href="#faq3" className="collapsed">What is the duration of survery process ? <i className="icofont-simple-up"></i></a>
                <div id="faq3" className="collapse" data-parent=".faq-list">
                  <p>
                    It will take approximately 5 - 7 working days.
              </p>
                </div>
              </li>

              <li data-aos="fade-up" data-aos-delay="200">
                <a data-toggle="collapse" href="#faq4" className="collapsed">What documents are required prior to survery ? <i className="icofont-simple-up"></i></a>
                <div id="faq4" className="collapse" data-parent=".faq-list">
                  <p>
                    All property related documents with survery no and location of the property should be provided. You can upload the soft copy of these documents in our system once you choose our service.
                    In case of hard copies of the documents, PropWindow field operations team will pick up from your door step.
              </p>
                </div>
              </li>

              <li data-aos="fade-up" data-aos-delay="300">
                <a data-toggle="collapse" href="#faq5" className="collapsed">What Reports do I get from the Surveyor? <i className="icofont-simple-up"></i></a>
                <div id="faq5" className="collapse" data-parent=".faq-list">
                  <p>
                    You will receive a detailed report with all survery details along with deviations if any. These reports will be available online and also can be delivered at your door step.
              </p>
                </div>
              </li>

              <li data-aos="fade-up" data-aos-delay="400">
                <a data-toggle="collapse" href="#faq6" className="collapsed">What are the pay terms ? <i className="icofont-simple-up"></i></a>
                <div id="faq6" className="collapse" data-parent=".faq-list">
                  <p>
                    You have to pay upfront and it's a pre-paid service.
              </p>
                </div>
              </li>
            </ul>

          </div>
        </section>
        {/* FAQ Section Ends */}

      </main>

    );

  }
}


// function App() {
//   return (
//     <div className="App">
//     </div>
//   );
// }

export default HomeLoan;
