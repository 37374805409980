import React, { Component } from 'react';
import $ from "jquery";
import FormErrors from "./FormErrors";
import Validate from "./utility/FormValidation";
import {Auth} from 'aws-amplify';
import {connect} from 'react-redux';
import {getLoggedInUser} from './actions/index'
import store from './store';
import {Redirect} from 'react-router-dom';


const userAuthState = {
  isLoggedIn: false,
  userEmail: "",
  userName: ""
}

class CustomerPaymentHome extends Component {

  

   /**
   * ReactJS Life Cycle Method, triggered when the DOM elements are loaded and ready to render output.
   */
  componentDidMount() {

    console.log(" I AM INSIDE COMPONENT DID MOUNT CUSTOMER-HOME PAGE -this.props.isLoggedIn !!!!!!!!!!!!!!!!!!! ");
    // this.props.getUser();
    
    console.log("1111111111111userToken : " + localStorage.getItem("userToken"))
    console.log("1111111111111userEmai : " + localStorage.getItem("userEmail"))
    console.log("1111111111111 isLoggedIn : " + localStorage.getItem("isLoggedIn"))

    console.log("localStorage.getItem USER ........");
    console.log(localStorage.getItem("user"));

    //This method will be called if the user is visiting the site in thier mobile browser.
    this.convertToMobile();
  }
 
  /**
   *  This method will be used to convert the DOM elemenets when 
   * the user is visiting the site in thier mobile browser.
   */
  convertToMobile() {
    console.log("INSIDE Convert to Mobile")
    // Smooth scroll for the navigation menu and links with .scrollto classes
    //&& window.location.hostname === this.hostname
    var scrolltoOffset = $('#header').outerHeight() - 1;
    $(document).on('click', '.nav-menu a, .mobile-nav a, .scrollto', function (e) {
      if (window.location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '')
      ) {
        var target = $(this.hash);
        if (target.length) {
          e.preventDefault();

          var scrollto = target.offset().top - scrolltoOffset;

          if ($(this).attr("href") === '#header') {
            scrollto = 0;
          }

          $('html, body').animate({
            scrollTop: scrollto
          }, 1500, 'easeInOutExpo');

          if ($(this).parents('.nav-menu, .mobile-nav').length) {
            $('.nav-menu .active, .mobile-nav .active').removeClass('active');
            $(this).closest('li').addClass('active');
          }

          if ($('body').hasClass('mobile-nav-active')) {
            $('body').removeClass('mobile-nav-active');
            $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
            $('.mobile-nav-overly').fadeOut();
          }
          return false;
        }
      }
    });

    // Activate smooth scroll on page load with hash links in the url
    $(document).ready(function () {
      if (window.location.hash) {
        var initial_nav = window.location.hash;
        if ($(initial_nav).length) {
          var scrollto = $(initial_nav).offset().top - scrolltoOffset;
          $('html, body').animate({
            scrollTop: scrollto
          }, 1500, 'easeInOutExpo');
        }
      }
    });

    // Mobile Navigation
    if ($('.nav-menu').length) {
      var $mobile_nav = $('.nav-menu').clone().prop({
        class: 'mobile-nav d-lg-none'
      });
      $('body').append($mobile_nav);
      $('body').prepend('<button type="button" className="mobile-nav-toggle d-lg-none"><i className="icofont-navigation-menu"></i></button>');
      $('body').append('<div className="mobile-nav-overly"></div>');

      $(document).on('click', '.mobile-nav-toggle', function (e) {
        $('body').toggleClass('mobile-nav-active');
        $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
        $('.mobile-nav-overly').toggle();
      });

      $(document).on('click', '.mobile-nav .drop-down > a', function (e) {
        e.preventDefault();
        $(this).next().slideToggle(300);
        $(this).parent().toggleClass('active');
      });

      $(document).click(function (e) {
        var container = $(".mobile-nav, .mobile-nav-toggle");
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          if ($('body').hasClass('mobile-nav-active')) {
            $('body').removeClass('mobile-nav-active');
            $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
            $('.mobile-nav-overly').fadeOut();
          }
        }
      });
    } else if ($(".mobile-nav, .mobile-nav-toggle").length) {
      $(".mobile-nav, .mobile-nav-toggle").hide();
    }


    // Navigation active state on scroll
    var nav_sections = $('section');
    var main_nav = $('.nav-menu, .mobile-nav');

    $(window).on('scroll', function () {
      var cur_pos = $(this).scrollTop() + 200;

      nav_sections.each(function () {
        var top = $(this).offset().top,
          bottom = top + $(this).outerHeight();

        if (cur_pos >= top && cur_pos <= bottom) {
          if (cur_pos <= bottom) {
            main_nav.find('li').removeClass('active');
          }
          main_nav.find('a[href="#' + $(this).attr('id') + '"]').parent('li').addClass('active');
        }
        if (cur_pos < 300) {
          $(".nav-menu ul:first li:first").addClass('active');
        }
      });
    });

    // Toggle .header-scrolled class to #header when page is scrolled
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('#header').addClass('header-scrolled');
      } else {
        $('#header').removeClass('header-scrolled');
      }
    });

    if ($(window).scrollTop() > 100) {
      $('#header').addClass('header-scrolled');
    }

    // Back to top button
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('.back-to-top').fadeIn('slow');
      } else {
        $('.back-to-top').fadeOut('slow');
      }
    });

    $('.back-to-top').click(function () {
      $('html, body').animate({
        scrollTop: 0
      }, 1500, 'easeInOutExpo', function () {
        $(".nav-menu ul:first li:first").addClass('active');
      });

      return false;
    });

  }

  state = {
    isLoggedIn: false,
    email: "",
    password: "",
    confirmpassword: "",
    tabClassName : "tab-pane active show",
    errors: {
      cognito: null,
      blankfield: false,
      passwordmatch: false
    }
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
        passwordmatch: false
      }
    });
  }

  handleSubmit = async event => {
    event.preventDefault();

    console.log("inside handle submit registration form");

    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
    }

    // AWS Cognito integration here

    try { 
      console.log(" CALLING COGNITO !!! ! ! !  ! ");
      console.log("........1.....email. : ");
      console.log(this.state.email)

      console.log("........1.....password. : ");
      console.log(this.state.password)


      const signUpResponse = await Auth.signUp(
            {
              username:this.state.email,
              password:this.state.password,
               attribute : {
                   email : this.state.email
               } 
            } );
            console.log(signUpResponse);
            this.props.history.push("/land-survey");

    } catch (error ) {
        console.log("ERROR OCCURRED !!! ! ! !  ! ");
        this.setState({
          errors: { ...this.state.errors,cognito:error }
        });
    }

  };

  onInputChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    document.getElementById(event.target.id).classList.remove("is-danger");
  }

  render() {
    return (
      !localStorage.getItem("isLoggedIn")  ? 
     (<Redirect  to="/"/> ):      
     
      <main id="main">
    <section id="contact" class="contact section-bg">
      <div class="container">

        <div class="section-title" data-aos="fade-up">
          <br/>
          <h2>Customer Payment Page</h2>
        </div>

        <form action="forms/contact.php" method="post" role="form" class="php-email-form">
            <div class="row" style={{border:'0px solid green'}}>

            <div class="col-sm" >
              <p style={{fontWeight: 'bold'}}>PERSONAL DETAILS</p>
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="name">First Name</label>
                  <input type="text" name="name" class="form-control" id="name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                  <div class="validate"></div>
                </div>
                <div class="form-group col-md-6">
                  <label for="name">Last Name</label>
                  <input type="text" name="name" class="form-control" id="name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                  <div class="validate"></div>
                </div>
              </div>

              <div class="form-group">
                <label for="name">Your Email</label>
                <input type="email" class="form-control" name="email" id="email" data-rule="email" data-msg="Please enter a valid email" />
                <div class="validate"></div>
            </div>
            <div class="form-group">
              <label for="name">Password</label>
              <input type="password" class="form-control" name="email" id="email" data-rule="email" data-msg="Please enter a valid email" />
              <div class="validate"></div>
          </div>
          <p style={{fontWeight: 'bold'}}>PAYMENT METHOD</p>
            <div class="form-group">
              <input type="radio" name="name" class="form-control" /> <label for="name">First Name</label>
            </div>

          <div class="form-group">
            <label for="name">Your Email</label>
            <input type="email" class="form-control" name="email" id="email" data-rule="email" data-msg="Please enter a valid email" />
            <div class="validate"></div>
        </div>


        </div>
            <div class="col-sm" >
              <p>Billing Details</p>
              <div class="form-group">
                <label for="name">Subject</label>
                <input type="text" class="form-control" name="subject" id="subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                <div class="validate"></div>
              </div>
              <div class="text-center">
                <div class='pm-button'><a href='https://www.payumoney.com/paybypayumoney/#/6331204134B2D38B89CCD5BF509CF00E'><img src='https://www.payumoney.com/media/images/payby_payumoney/new_buttons/21.png' /></a></div> 
              </div>
            </div>
        </div>
      </form>
      </div>
    </section> 
 
            
      </main>
    )
  }
}

const mapStateToProps = (state) => ({
	isLoggedIn: state.userAuth.isLoggedIn,
	userEmail: state.userAuth.userEmail,
	userName: state.userAuth.userName,
	userAuthToken: state.userAuth.userAuthToken 
});

const mapDispatchToProps = dispatch => ({
	getUser: (payload) => dispatch(getLoggedInUser(userAuthState))
});
export default connect(mapStateToProps, mapDispatchToProps)(CustomerPaymentHome);

//export default CustomerPaymentHome;