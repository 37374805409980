import React from 'react';
import $ from "jquery";
// import logo from './logo.svg';
// import './App.css';

class LandSurvey extends React.Component {

  /**
   * ReactJS Life Cycle Method, triggered when the DOM elements are loaded and ready to render output.
   */
  componentDidMount() {
    console.log(" I AM INSIDE COMPONENT DID MOUNT LAND-SURVEY !!!!!!!!!!!!!!!!!!! ");
    //This method will be called if the user is visiting the site in thier mobile browser.
    this.convertToMobile();
  }

  /**
   *  This method will be used to convert the DOM elemenets when 
   * the user is visiting the site in thier mobile browser.
   */
  convertToMobile() {
    console.log("INSIDE Convert to Mobile")
    // Smooth scroll for the navigation menu and links with .scrollto classes
    //&& window.location.hostname === this.hostname
    var scrolltoOffset = $('#header').outerHeight() - 1;
    $(document).on('click', '.nav-menu a, .mobile-nav a, .scrollto', function (e) {
      if (window.location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '')
      ) {
        var target = $(this.hash);
        if (target.length) {
          e.preventDefault();

          var scrollto = target.offset().top - scrolltoOffset;

          if ($(this).attr("href") === '#header') {
            scrollto = 0;
          }

          $('html, body').animate({
            scrollTop: scrollto
          }, 1500, 'easeInOutExpo');

          if ($(this).parents('.nav-menu, .mobile-nav').length) {
            $('.nav-menu .active, .mobile-nav .active').removeClass('active');
            $(this).closest('li').addClass('active');
          }

          if ($('body').hasClass('mobile-nav-active')) {
            $('body').removeClass('mobile-nav-active');
            $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
            $('.mobile-nav-overly').fadeOut();
          }
          return false;
        }
      }
    });

    // Activate smooth scroll on page load with hash links in the url
    $(document).ready(function () {
      if (window.location.hash) {
        var initial_nav = window.location.hash;
        if ($(initial_nav).length) {
          var scrollto = $(initial_nav).offset().top - scrolltoOffset;
          $('html, body').animate({
            scrollTop: scrollto
          }, 1500, 'easeInOutExpo');
        }
      }
    });

    // Mobile Navigation
    if ($('.nav-menu').length) {
      var $mobile_nav = $('.nav-menu').clone().prop({
        class: 'mobile-nav d-lg-none'
      });
      $('body').append($mobile_nav);
      $('body').prepend('<button type="button" class="mobile-nav-toggle d-lg-none"><i class="icofont-navigation-menu"></i></button>');
      $('body').append('<div class="mobile-nav-overly"></div>');

      $(document).on('click', '.mobile-nav-toggle', function (e) {
        $('body').toggleClass('mobile-nav-active');
        $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
        $('.mobile-nav-overly').toggle();
      });

      $(document).on('click', '.mobile-nav .drop-down > a', function (e) {
        e.preventDefault();
        $(this).next().slideToggle(300);
        $(this).parent().toggleClass('active');
      });

      $(document).click(function (e) {
        var container = $(".mobile-nav, .mobile-nav-toggle");
        if (!container.is(e.target) && container.has(e.target).length === 0) {
          if ($('body').hasClass('mobile-nav-active')) {
            $('body').removeClass('mobile-nav-active');
            $('.mobile-nav-toggle i').toggleClass('icofont-navigation-menu icofont-close');
            $('.mobile-nav-overly').fadeOut();
          }
        }
      });
    } else if ($(".mobile-nav, .mobile-nav-toggle").length) {
      $(".mobile-nav, .mobile-nav-toggle").hide();
    }


    // Navigation active state on scroll
    var nav_sections = $('section');
    var main_nav = $('.nav-menu, .mobile-nav');

    $(window).on('scroll', function () {
      var cur_pos = $(this).scrollTop() + 200;

      nav_sections.each(function () {
        var top = $(this).offset().top,
          bottom = top + $(this).outerHeight();

        if (cur_pos >= top && cur_pos <= bottom) {
          if (cur_pos <= bottom) {
            main_nav.find('li').removeClass('active');
          }
          main_nav.find('a[href="#' + $(this).attr('id') + '"]').parent('li').addClass('active');
        }
        if (cur_pos < 300) {
          $(".nav-menu ul:first li:first").addClass('active');
        }
      });
    });

    // Toggle .header-scrolled class to #header when page is scrolled
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('#header').addClass('header-scrolled');
      } else {
        $('#header').removeClass('header-scrolled');
      }
    });

    if ($(window).scrollTop() > 100) {
      $('#header').addClass('header-scrolled');
    }

    // Back to top button
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('.back-to-top').fadeIn('slow');
      } else {
        $('.back-to-top').fadeOut('slow');
      }
    });

    $('.back-to-top').click(function () {
      $('html, body').animate({
        scrollTop: 0
      }, 1500, 'easeInOutExpo', function () {
        $(".nav-menu ul:first li:first").addClass('active');
      });

      return false;
    });

  }


  render() {
    return (
      <main id="main">
        {/* Banner Section Starts */}
        <section id="hero">
          <div className="container">
            <div class="row" >
              <div
                class="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-right"
                data-aos="fade-up" >
                <div class="content pt-4 pt-lg-0" >
                <i class="icofont-tick-boxed"></i>&nbsp;Experienced Legal Team <br/><br/>
                <i class="icofont-tick-boxed"></i>&nbsp;Legal Opinion <br/><br/>
                <i class="icofont-tick-boxed"></i>&nbsp;Land Transactions History <br/><br/>
                <a href="#about" class="btn-get-started scrollto">Get Started</a>
                </div>
              </div>
              <div
                class="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-right"
                data-aos="fade-up" >
                <div class="content pt-4 pt-lg-0" >
                  <i class="icofont-tick-boxed"></i>&nbsp;Land Ownership Verification <br/><br/>
                  <i class="icofont-tick-boxed"></i>&nbsp;Land Partition Verification<br/><br/>
                  <i class="icofont-tick-boxed"></i>&nbsp;Land Conversion Verification <br/><br/>
                  <i class="icofont-tick-boxed"></i>&nbsp;Land Mutation Verification <br/><br/>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Banner Section Ends */}

        {/* Features Section Starts */}
        <section id="features" class="features">
      <div class="container">
        <h3>Open Plot/Land Document Verification Service</h3>
        <div class="row">
          <div class="col-lg-6 mt-2 mb-tg-0 order-2 order-lg-1">
            <ul class="nav nav-tabs flex-column">
              <li class="nav-item" data-aos="fade-up">
                <a class="nav-link active show" data-toggle="tab" href="#tab-1">
                  <h4>Why PropWindow ? </h4>
                </a>
              </li>
              <li class="nav-item mt-2" data-aos="fade-up" data-aos-delay="100">
                <a class="nav-link" data-toggle="tab" href="#tab-2">
                  <h4>How to book our service ? </h4>
                </a>
              </li>
            </ul>
          </div>
          <div class="col-lg-6 order-1 order-lg-2" data-aos="zoom-in">
            <div class="tab-content">
              <div class="tab-pane active show" id="tab-1">
                <div class="row">
                <div class="col-sm" style={{ textAlign: "left" }}>
                   <p style={{ color: "#009cea" }}><i class="icofont-google-map"></i> Experienced Legal Experts<br/></p>
                   <p style={{ fontSize: "small" }}>
                    Our legal experts have more than 10 years of experience in field of land Verification. 
                    They will  inspect for upto 30 years of land transactions, before giving legal opinion.
                   </p>
                   <p style={{ color: "#009cea" }}><i class="icofont-rupee"></i> Transparent Pricing<br/></p>
                   <p style={{ fontSize: "small" }}>
                    Our services will ensures transparent pricing, you will be paying what is being done actually. <br/>
                   </p>
                    </div>
                <div class="col-sm" style={{ textAlign: "left" }}>
                  <p style={{ color: "#009cea" }}><i class="icofont-tick-boxed"></i> Data Accuracy<br/></p>
                  <p style={{ fontSize: "small" }}>
                    Our legal expert will do a through verification of aspects like land ownership transfers, partitions verification, land conversions and any mutations  
                  </p>
                  <br/>
                  <p style={{ color: "#009cea" }}><i class="icofont-ssl-security"></i>&nbsp;Delivery <br/></p>
                  <p style={{ fontSize: "small" }}>
                    Our process of land verification is organized and we will deliver the reported in the stipulated time frame of 7 - 10  days depending on the documents provided.
                  </p>
                  </div>
                </div>
              </div>
              <div class="tab-pane" id="tab-2">
                <div class="row">
                  <div class="col-sm" >
                     <p style={{ color: "#009cea" }}><i class="icofont-listine-dots"></i>&nbsp;Choose the service.<br/></p>
                     <p style={{ fontSize: "small" }}>
                       Choose the service you are looking from our list of services. Once you choose the service, you have to upload documents related to the open plot.
                     </p>
                     <br/><br/>
                     <p style={{ color: "#009cea" }}><i class="icofont-package"></i> Combo Package<br/></p>
                     <p style={{ fontSize: "small" }}>
                      We have combo packages for your plot, please refer to our pricing list for getting discount.
                     </p>
                  </div>
                  <div class="col-sm" >
                    <p style={{ color: "#009cea" }}><i class="icofont-pay"></i> Payment <br/></p>
                    <p style={{ fontSize: "small" }}>
                      Upon selecting your package proceed to our secured payment process. Upon succesful payment, your order will be created and our team will be in touch with you.
                     </p>
                    <br/>
                    <p style={{ color: "#009cea" }}><i class="icofont-ssl-security"></i>&nbsp;Deliverables <br/></p>
                    <p style={{ fontSize: "small" }}>
                      Once the land verification is over, our experienced legal team will deliver the legal opinion document on their letter head. 
                    </p>
                    </div>
                    
                  </div>
              </div>
              <div class="ui-group-buttons">
                {/* <!-- Button trigger modal --> */}
                <button type="button" class="btn btn-primary btn-lg" data-toggle="modal" data-target="#exampleModal">Enquire Now</button>
              </div>
              {/* <!-- <div class='pm-button'>
                <a href='https://www.payumoney.com/paybypayumoney/#/6331204134B2D38B89CCD5BF509CF00E'>
                <img src='https://www.payumoney.com/media/images/payby_payumoney/new_buttons/21.png' />
              </a>
              </div>  --> */}
            </div>
          </div>
          
        </div>

      </div>
    </section>

        {/* Features Section Ends */}

        {/* FAQ Section Ends */}
        <section id="faq" class="faq">
      <div class="container">

        <div class="section-title" data-aos="fade-up">
          <h2>Frequently Asked Questions</h2>
        </div>

        <ul class="faq-list">

          <li data-aos="fade-up">
            <a data-toggle="collapse" href="#faq1" class=""> What is the importance of  Land Verification ? <i class="icofont-simple-up"></i></a>
            <div id="faq1" class="collapse show" data-parent=".faq-list">
              <p>Land verification is very important before you decide to buy any land. Only a legal expert can identify any litigations based on the history of transactions.</p>
            </div>
          </li>
          <li data-aos="fade-up"  data-aos-delay="500">
            <a data-toggle="collapse" class="collapsed" href="#faq2">How Land Verification is being done ?  <i class="icofont-simple-up"></i></a>
            <div id="faq2" class="collapse" data-parent=".faq-list">
              <p> Our Legal Experts will go through the land history transactions upto 30 years. Our legal expert will also verify the following.
                <ul>
                  <li>Land Ownership Verification</li>
                  <li>Land Partition Verification</li>
                  <li>Land Conversion Verification</li>
                  <li>Land Mutation Verification</li>
                </ul>
              </p>
            </div>
          </li>

          <li data-aos="fade-up" data-aos-delay="100">
            <a data-toggle="collapse" href="#faq3" class="collapsed">What is the duration of land verification process ? <i class="icofont-simple-up"></i></a>
            <div id="faq3" class="collapse" data-parent=".faq-list">
              <p>
                 It will take approximately 7 - 10 working days.
              </p>
            </div>
          </li>

          <li data-aos="fade-up" data-aos-delay="200">
            <a data-toggle="collapse" href="#faq4" class="collapsed">What documents are required prior to Land Verification ? <i class="icofont-simple-up"></i></a>
            <div id="faq4" class="collapse" data-parent=".faq-list">
              <p>
                 All property related documents with earlier historical documents of the property should be provided. 
                 You can upload the soft copy of these documents in our system once you choose our service.
                 In case of hard copies of the documents, PropWindow field operations team will pick up from your door step.
              </p>
            </div>
          </li>

          <li data-aos="fade-up" data-aos-delay="300">
            <a data-toggle="collapse" href="#faq5" class="collapsed">What Reports do I get from the Legal Expert? <i class="icofont-simple-up"></i></a>
            <div id="faq5" class="collapse" data-parent=".faq-list">
              <p>
                You will receive a detailed report with all land verification. It will be a legal opinion on the letter head of the leal expert.
                These reports will be available online and also can be delivered at your door step.
              </p>
            </div>
          </li>

          <li data-aos="fade-up" data-aos-delay="400">
            <a data-toggle="collapse" href="#faq6" class="collapsed">What are the pay terms ? <i class="icofont-simple-up"></i></a>
            <div id="faq6" class="collapse" data-parent=".faq-list">
              <p>
                You have to pay upfront and it's a pre-paid service.
              </p>
            </div>
          </li>
        </ul>

      </div>
    </section>

        {/* FAQ Section Ends */}

      </main>

    );

  }
}


// function App() {
//   return (
//     <div className="App">
//     </div>
//   );
// }

export default LandSurvey;
