import { Auth } from 'aws-amplify';

export const userServices = {
    login,
    logout,
    register,
    getUser,
    getById,
    update,
    delete: _delete
};

async function login(username, password) {
    try {
        const signInResponse = await Auth.signIn(this.state.email, this.state.password);
        console.log(signInResponse);
    } catch (error) {
        console.log("ERROR OCCURRED !!! ! ! !  ! ");
      
    }
  
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

async function getUser() {
    try {
        //get the current User Session from AWS Cognito
        const currentSession = await Auth.currentSession();
        console.log(" Printing currentSession  FROM getUser: ==> ");
        console.log(currentSession);
        console.log(" Printing currentSession  payload: ==> ");
        console.log(currentSession.getIdToken().payload);

        console.log(" Printing currentSession  payload: email ==> ");
        console.log(currentSession.getIdToken().payload.email);
        console.log(currentSession.getIdToken().payload.email_verified);
        console.log(currentSession.getIdToken().getJwtToken());
        
        return currentSession.getIdToken().payload;

      } catch (error) {
        console.log("ERROR OCCURRED !!! ! ! !  ! ");
      }
  
}

function getById(id) {
}

function register(user) {
}

function update(user) {
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
}

function handleResponse(response) {
}