//This reducer is used to store the user level data across multiple pages in Workspace
// especially something like reconUnit that user chooses in any page. This is to be enhanced further

const userReducers = (state = 0, action) => {
    switch(action.type){
        case 'SETRECONUNIT':
            return state;
                
        default:
            return state;
    }
}

export default userReducers;