import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import { Route, BrowserRouter , Switch } from "react-router-dom";
// import Users from "./users";
// import Contact from "./contact";
import Notfound from "./notfound";
import Header from "./Header";
import Footer from "./Footer";
import CustomerHeader from "./components/CustomerHeader";

// Land Related 
import LandSurvey from "./LandSurvey";
import LandVerification from "./LandVerification";
import LandValuation from "./LandValuation";
import LandSaleAgreementFranking from "./LandSaleAgreementFranking";
import LandReSaleAgreement from "./LandReSaleAgreement";
import LandSaleDeedAgreement from "./LandSaleDeedAgreement";
import LandRegularization from "./LandRegularization";

import BuildingVerification from "./BuildingVerification";
import BuildingInspection from "./BuildingInspection";
import BuildingValuation from "./BuildingValuation";
import BuildingSaleAgreementFranking from "./BuildingSaleAgreementFranking";
import BuildingReSaleAgreement from "./BuildingReSaleAgreement";
import BuildingSaleDeedAgreement from "./BuildingSaleDeedAgreement";

import BuildingPermission from "./BuildingPermission";
import ElectricConnection from "./ElectricConnection";
import BorewellConnection from "./BorewellConnection";

import HouseNumber from "./HouseNumber";
import DrinkingWaterConnection from "./DrinkingWaterConnection";
import OccupancyCertificate from "./OccupancyCertificate";

import HomeLoan from "./HomeLoan";
import NRIHomeLoan from "./NRIHomeLoan";
import LandPurchaseLoan from "./LandPurchaseLoan";

import LegalGPAServices from "./LegalGPAServices";
import LegalDisputeServices from "./LegalDisputeServices";
import LegalMortgageServices from "./LegalMortgageServices";

import Login from "./Login";
import Register from "./Register";
import CustomerHome from "./CustomerHome";
import CustomerUploadDocument from "./CustomerUploadDocuments";

import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import logger from "redux-logger";
import thunk from 'redux-thunk';
import rootreducer from './reducers';

import CustomerPaymentHome from "./CustomerPaymentHome";



import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);



// import logo from './logo.svg';
// import './App.css';

// Amplify.configure  ( {
//   Auth : {
//     mandatorySignId  : true,
//     region : config.cognito.REGION,
//     UserPoolId : config.cognito.USER_POOL_ID,
//     UserPoolWebClient : config.cognito.APP_CLIENT_ID
//   }
// });

const middleware = applyMiddleware(logger, thunk);

const appStore = (createStore(rootreducer, middleware));

console.log("store ==> in INDEX.JS...........:" );
console.log(appStore);

const routing = (
  <Provider store={appStore}>
    <BrowserRouter >
    <div>
      <Header/>
      <hr />
      <Switch>
        <Route exact path="/" component={App} />
        {/* Login Services Routing Start*/}
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/customer-payment-page" component={CustomerPaymentHome} />
        
        {/* Login Services Routing End* /}


        {/* Open Land/Plot Related Services Routing Start*/}
        <Route path="/land-survey" component={LandSurvey} />
        <Route path="/land-documents-verification" component={LandVerification} />
        <Route path="/land-valuation" component={LandValuation} />
        <Route path="/land-sale-agreement-franking" component={LandSaleAgreementFranking} />
        <Route path="/land-resale-agreement" component={LandReSaleAgreement} />
        <Route path="/land-sale-deed-agreement" component={LandSaleDeedAgreement} />
        {/* Open Land/Plot Related Services Routing End*/}

        {/* Building Related Services Routing Start*/}
        <Route path="/building-documents-verification" component={BuildingVerification} />
        <Route path="/building-inspection" component={BuildingInspection} />
        <Route path="/building-valuation" component={BuildingValuation} />
        <Route path="/building-sale-agreement-franking" component={BuildingSaleAgreementFranking} />
        <Route path="/building-resale-agreement" component={BuildingReSaleAgreement} />
        <Route path="/building-sale-deed-agreement" component={BuildingSaleDeedAgreement} />
        {/* Building Related Services Routing End*/}

        {/* Before Construction Related Services Routing Start*/}
        <Route path="/building-permission" component={BuildingPermission} />
        <Route path="/electric-connection" component={ElectricConnection} />
        <Route path="/borewell-connection" component={BorewellConnection} />
        <Route path="/land-regularization" component={LandRegularization} />
        {/* Before Construction Related Services Routing End*/}

        {/* After Construction Related Services Routing Start*/}
        <Route path="/house-number" component={HouseNumber} />
        <Route path="/drinking-water-connection" component={DrinkingWaterConnection} />
        <Route path="/building-occupancy-certificate" component={OccupancyCertificate} />
        {/* After Construction Related Services Routing End*/}

        {/* Home/Land/NRI Loan Related Services Routing Start*/}
        <Route path="/home-loan" component={HomeLoan} />
        <Route path="/nri-home-loan" component={NRIHomeLoan} />
        <Route path="/land-purchase-loan" component={LandPurchaseLoan} />
        {/* Home/Land/NRI Loan Related Services Routing End*/}

        {/* Legal Related Services Routing End*/}
        <Route path="/legal-gpa-services" component={LegalGPAServices} />
        <Route path="/land-dispute-services" component={LegalDisputeServices} />
        <Route path="/legal-mortgage-services" component={LegalMortgageServices} />
        {/* Legal Related Services Routing End*/}
        
        {/* Customer Related Pages Routing End*/}
        <Route path="/customer-home-page" component={CustomerHome} />
        <Route path="/customer-upload-documents" component={CustomerUploadDocument} />
        {/* Customer Related Services Routing End*/}

        {/* 404 Page Error Routing */}
        <Route component={Notfound} />
      </Switch>
      <Footer />

    </div>
  </BrowserRouter>
  </Provider>
);

ReactDOM.render(routing, document.getElementById("root"));

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();




