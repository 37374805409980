//REDUCERS

import {combineReducers} from "redux";
import userReducers from "./userReducers";
import authenticationReducer from "./authenticationReducer";

const rootReducer = combineReducers({
	user: userReducers,
	userAuth: authenticationReducer,

});

export default rootReducer;

